.indivisual-detail {
    .detailSection {
        padding-top: 2rem;
        .let-meknow-card {
            flex: 1.25;
            min-width: 350px;
            padding-right: 2rem;
            h4.card-name {
                font-size: 13px;
                letter-spacing: 1px;
            }
            .card-addr {
                &.card-text {
                    font-size: 10px;
                    letter-spacing: 1px;
                }
            }
            .categoryType {
                span {
                    font-size: 20px !important;
                }
                .text-start {
                    label {
                        font-size: 10px;
                        letter-spacing: 1px;
                    }
                }
            }
        }
        .let-meknow-text {
            flex: 3;
            // display: flex;
            h3 {
                font-weight: normal;
                font-size: 30px;
                color: #FFFFFF;
            }
            .date {
                font-weight: 500;
                font-size: 16px;
                color: #ABABAB;
            }
            p {
                color: #ABABAB;
            }
            .flex-text-row {
                .text-blue {
                    color: #564ece;
                }
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #FFFFFF;
                div {
                    color: inherit;
                }
            }
        }
    }
    .view-update-link {
        cursor: pointer;
        color: #564ece;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        &:hover {
            color: #FFFFFF;
        }
    }
    @media screen and (max-width: 2560px) {
        .card-container {
            .watchlist-card-box {
                // flex: 0 1 20%;
                // width: 20%;
            }
        }
    }
    @media screen and (max-width: 1563px) {}
    @media screen and (max-width: 1440px) {}
    @media screen and (max-width: 1024px) {}
    @media screen and (max-width: 900px) {}
    @media screen and (max-width: 768px) {}
    @media screen and (max-width: 600px) {}
    @media screen and (max-width: 561px) {
        .detailSection {
            .let-meknow-card {
                flex: 1;
                width: 100%;
                margin-bottom: 1rem;
            }
        }
    }
}