 //----------- Faq section  css ---------------//
 .detailSection {
     padding-top: 80px;
     .mb-5 {
         @media (max-width: 991px) {
             margin-bottom: 25px !important;
         }
     }
     .px-5 {
         @media (max-width: 991px) {
             padding-left: 20px !important;
             padding-right: 20px !important;
         }
     }
     .d-flex {
         flex-wrap: wrap;
         padding-bottom: 10px;
     }
     .rightSec {
         padding-left: 30px;
         @media (max-width: 991px) {
             padding: 15px;
         }
     }
     .back-link {
         position: absolute;
         @media (max-width: 991px) {
             position: relative;
             margin-bottom: 40px;
         }
         a {
             margin-bottom: 10px;
             display: inherit;
             transition: all ease 0.4s;
             &:hover {
                 margin-left: -2px;
                 transition: all ease 0.4s;
             }
         }
     }
     .darkBlk {
         background: #0b0d19;
         border-radius: 24px;
         padding: 30px 38px 30px;
         @media (max-width: 991px) {
             padding: 20px;
         }
         .songtxt {
             font-weight: 500;
             font-size: 18px;
             color: $blue2-light-color;
         }
         .footerSec {
             color: #ffffff;
             i {
                 font-style: italic;
                 font-weight: 300;
                 font-size: 18px;
                 line-height: 32px;
                 margin-left: 5px;
             }
         }
         p {
             font-weight: 500;
             font-size: 16px;
             line-height: 21px;
             color: rgba(255, 255, 255, 0.66);
         }
         h2 {
             font-size: 30px;
             line-height: 44px;
             color: $white-color;
             @media (max-width: 991px) {
                 font-size: 20px;
             }
         }
         .card {
             max-width: 510px;
             margin: 0 auto;
             background: linear-gradient( 71.45deg, rgba(13, 11, 33, 0.54) 14.26%, rgba(86, 78, 206, 0.1242) 113.99%);
             border: 1.10129px solid #564ECE;
             box-sizing: border-box;
             //  backdrop-filter: blur(11px);
             border-radius: 12px;
             padding: 30px;
             @media (max-width: 991px) {
                 padding: 20px;
             }
             .card-img-top {
                 border-radius: 30px;
             }
             .card-body {
                 font-size: 16px;
                 color: #ffffff;
                 font-weight: 400;
                 padding-left: 0;
                 padding-right: 0;
                 .headerRgt {
                     img {
                         margin-left: 7px;
                     }
                 }
                 .card-text {
                     color: rgba(255, 255, 255, 0.31);
                     font-size: 14px;
                     font-weight: 500;
                     margin-bottom: 10px;
                 }
                 .categoryType {
                     justify-content: space-between;
                     span {
                         font-size: 21px;
                         line-height: 22px;
                         text-align: right;
                         color: #ffffff;
                         padding: 0 5px;
                         @media (max-width: 991px) {
                             text-align: left !important;
                             font-size: 16px;
                         }
                         label {
                             display: block;
                             font-weight: 500;
                             font-size: 14px;
                             line-height: 22px;
                             color: rgba(255, 255, 255, 0.5);
                             margin-bottom: 6px;
                         }
                     }
                 }
             }
         }
     }
     .accordion-button {
         box-shadow: none;
     }
     .accordionBlk {
         &.accordion {
             .accordion-item,
             .accordion-button {
                 background: transparent;
                 border: none;
             }
             .accordion-item {
                 margin-bottom: 38px;
                 background: #0b0d19;
                 border-radius: 24px;
                 @media (max-width: 991px) {
                     margin-bottom: 25px;
                 }
                 .roy-contract {
                     p {
                         font-style: normal;
                         font-weight: normal;
                         font-size: 16px;
                         line-height: 26px;
                         /* or 162% */
                         color: rgba(0, 0, 0, 0.7);
                     }
                     .sub-text {
                         font-style: normal;
                         font-weight: 500;
                         font-size: 18px;
                         line-height: 42px;
                         /* or 233% */
                         letter-spacing: -0.05em;
                         color: rgba(0, 0, 0, 0.31);
                     }
                     button {
                         &:hover {
                             color: #564ece!important;
                         }
                     }
                 }
                 ul {
                     padding-left: 0;
                     li {
                         display: flex;
                         justify-content: space-between;
                         margin-bottom: 12px;
                         color: $white-color;
                         @media (max-width: 991px) {
                             margin-bottom: 7px;
                         }
                         label {
                             font-weight: 500;
                             font-size: 16px;
                             flex: 0 0 35%;
                             padding-right: 15px;
                         }
                         p {
                             font-weight: 500;
                             font-size: 16px;
                             line-height: 20px;
                             text-align: right;
                             color: #ababab;
                             white-space: normal;
                             word-break: break-all;
                             &.basetext {
                                 color: $blue2-light-color;
                             }
                         }
                     }
                 }
                 .accordion-body {
                     padding: 6px 30px;
                     @media (max-width: 991px) {
                         padding: 6px 15px;
                     }
                 }
                 .accordion-button {
                     padding: 27px 30px;
                     box-shadow: none;
                     @media (max-width: 991px) {
                         padding: 20px;
                     }
                 }
             }
             .accordion-button {
                 font-size: 21px;
                 line-height: 38px;
                 color: #ababab;
                 @media (max-width: 991px) {
                     font-size: 16px;
                 }
                 &::after {
                     filter: invert(100%) sepia(7%) saturate(2%) hue-rotate(195deg) brightness(105%) contrast(101%);
                 }
             }
         }
     }
     // custom radio style//
     .custom-radios {
         input[type="checkbox"] {
             display: none;
             +label {
                 color: #333;
                 font-family: Arial, sans-serif;
                 font-size: 14px;
                 span {
                     display: flex;
                     width: 34px;
                     height: 34px;
                     align-items: center;
                     justify-content: center;
                     background: rgba(57, 54, 104, 0.91);
                     vertical-align: middle;
                     cursor: pointer;
                     border-radius: 50%;
                     background-repeat: no-repeat;
                     background-position: center;
                     text-align: center;
                     line-height: 44px;
                     img {
                         opacity: 0;
                         transition: all 0.3s ease;
                     }
                 }
             }
             &:checked+label span {
                 background: linear-gradient( 134.22deg, rgba(86, 78, 206, 0.91) 9.27%, rgba(0, 148, 255, 0.91) 142.83%) !important;
                 img {
                     opacity: 1;
                 }
             }
         }
     }
     .form-control {
         background: rgba(86, 78, 206, 0.2);
         border: none;
         border-radius: 36.5px;
         color: #ffffff;
         height: 59px;
         padding: 10px 26px;
         &::placeholder {
             color: #454163;
         }
         &:hover,
         &:active,
         &:focus {
             background: rgba(86, 78, 206, 0.2);
             border: none;
         }
     }
     .rgtTopSec {
         padding-top: 56px;
         padding-bottom: 53px;
         @media (max-width: 991px) {
             padding-top: 30px;
             padding-bottom: 30px;
         }
         h2 {
             font-weight: normal;
             font-size: 30px;
             line-height: 38px;
             color: #ffffff;
             margin-bottom: 20px;
             @media (max-width: 991px) {
                 font-size: 20px;
             }
         }
         form {
             label {
                 font-weight: 500;
                 font-size: 16px;
                 line-height: 20px;
                 color: rgba(255, 255, 255, 0.66);
                 margin-bottom: 27px;
                 position: relative;
                 width: 100%;
                 padding-right: 50px;
                 .custom-radios {
                     position: absolute;
                     right: 20px;
                     top: 0;
                     label {
                         margin-bottom: 0;
                         padding-right: 0;
                     }
                 }
             }
             button {
                 max-width: 380px;
                 width: 100%;
             }
         }
         p {
             font-style: normal;
             font-weight: 500;
             font-size: 14px;
             line-height: 20px;
             color: rgba(255, 255, 255, 0.66);
             margin-bottom: 27px;
             a {
                 display: block;
                 color: $blue2-light-color;
                 text-decoration: none;
                 font-weight: normal;
                 font-size: 18px;
                 line-height: 38px;
                 cursor: pointer;
             }
         }
     }
     .accordionBlkRight.accordion {
         .accordion-item {
             background: #16122b;
             box-shadow: 40px 40px 60px rgba(0, 0, 0, 0.25);
             border-radius: 40px;
             margin-bottom: 35px;
             .accordion-button {
                 color: #fff;
                 background-color: transparent;
                 flex-direction: column;
                 padding: 35px 68px 35px 44px;
                 align-items: baseline;
                 @media (max-width: 991px) {
                     padding: 30px 15px 30px;
                 }
                 &::after {
                     width: 34px;
                     height: 34px;
                     border-radius: 100px;
                     background: linear-gradient( 134.22deg, rgba(86, 78, 206, 0.36) 9.27%, rgba(0, 148, 255, 0.36) 142.83%);
                     z-index: 1;
                     position: absolute;
                     right: 30px;
                     top: 40%;
                 }
                 h3 {
                     font-weight: 400;
                     font-size: 21px;
                     line-height: 38px;
                     @media (max-width: 991px) {
                         font-size: 16px;
                         line-height: normal;
                     }
                 }
                 h2 {
                     font-weight: normal;
                     font-size: 37px;
                     line-height: 38px;
                     @media (max-width: 991px) {
                         font-size: 22px;
                         line-height: normal;
                     }
                 }
                 ul {
                     display: flex;
                     align-items: center;
                     list-style: none;
                     padding-left: 0;
                     margin-top: 5px;
                     margin-bottom: 0;
                     li {
                         margin-right: 20px;
                         &:last-child {
                             margin-right: 0;
                         }
                         img {
                             margin-right: 10px;
                         }
                     }
                 }
             }
             .accordion-body {
                 padding: 0px 58px 35px 44px;
                 @media (max-width: 991px) {
                     padding: 0 15px 20px 15px;
                 }
                 .minPrice {
                     background: #564ece;
                     border-radius: 3px;
                     letter-spacing: 0.03em;
                     color: rgba(239, 233, 255, 0.6);
                     padding: 0 12px;
                     margin-bottom: 9px;
                     display: inline-block;
                 }
                 .updates {
                     font-weight: 400;
                     font-size: 20px;
                     line-height: 30px;
                     color: #ffffff;
                     @media (max-width: 991px) {
                         font-size: 16px;
                         line-height: normal;
                     }
                 }
                 p {
                     font-weight: 400;
                     font-size: 14px;
                     line-height: 20px;
                     color: #ffffff;
                     margin-bottom: 25px;
                     @media (max-width: 991px) {
                         font-size: 13px;
                         margin-bottom: 15px;
                         line-height: normal;
                     }
                 }
                 .listHeading {
                     font-weight: 600;
                     font-size: 12px;
                     line-height: 14px;
                     color: #ffffff;
                     margin-bottom: 14px;
                 }
                 ul {
                     margin-bottom: 40px;
                     padding-left: 10px;
                     @media (max-width: 991px) {
                         margin-bottom: 25px;
                     }
                     li {
                         font-weight: 400;
                         font-size: 14px;
                         line-height: 32px;
                         color: rgba(222, 217, 217, 0.58);
                     }
                 }
                 .rewardsImg {
                     margin-bottom: 68px;
                     max-width: 100%;
                     @media (max-width: 991px) {
                         margin-bottom: 40px;
                     }
                 }
                 form {
                     .mb-3 {
                         padding-bottom: 35px;
                         @media (max-width: 991px) {
                             padding-bottom: 15px;
                         }
                     }
                     button {
                         width: 100%;
                     }
                 }
             }
             .accordion-button:not(.collapsed)::after {
                 background: linear-gradient( 134.22deg, rgba(86, 78, 206, 0.91) 9.27%, rgba(0, 148, 255, 0.91) 142.83%);
             }
             .accordion-button:not(.collapsed)::before {
                 background: url("/img/tick.png") no-repeat;
                 background-position: center center;
                 width: 18px;
                 height: 13px;
                 z-index: 2;
                 content: "";
                 position: absolute;
                 right: 38px;
                 top: 50%;
                 transform: translateY(-50%);
             }
         }
     }
     .access-tokens .access-tokens-section .cards-container .card-box .card-holder .single-card {
         background-color: #0b0d19;
     }
 }
 
 //MODAL
 .buck-details-modal {
     .close-icon {
         position: absolute;
         right: 1rem;
         top: 1rem;
     }
     h4 {
         font-weight: normal;
         font-size: 21px;
         color: #564ECE !important;
     }
     p {
         font-weight: 300!important;
         font-size: 16px!important;
         color: #FFFFFF!important;
     }
     .form-control {
         background: rgb(23 24 29);
         border-radius: 26px;
         padding: 0.5rem 1rem;
         font-style: normal;
         font-weight: 500;
         font-size: 16px;
         color: #FFF;
         border: 0px;
         outline: 0px;
     }
     .text-muted {
         font-weight: normal;
         font-size: 16px;
         color: rgba(86, 78, 206, 0.5)!important;
         span {
             color: #564ECE!important;
         }
     }
     button {
         min-width: 50%;
     }
 }
 
 .faq-section.detailsFaq {
     .row {
         justify-content: space-around;
         .col-md-6 {
             max-width: 519px;
         }
     }
     .shape-left {
         top: -100px;
     }
     h2 {
         font-weight: 600;
         font-size: 43px;
         line-height: 52px;
         color: $black-color;
         @media (max-width: 1200px) {
             font-size: 30px;
             line-height: normal;
         }
         @media (max-width: 991px) {
             font-size: 22px;
         }
     }
     .accordion-button {
         font-style: normal;
         font-weight: 500;
         font-size: 20px;
         line-height: normal;
         color: $black-color;
         padding: 30px 0px;
         background: rgb(0 0 0 / 0%);
         @media (max-width: 991px) {
             font-size: 16px;
             padding: 16px 0px;
         }
         &::after {
             background-image: url("../img/svg/arrow-down.svg");
             transform: rotate(-180deg);
         }
         &:hover {
             color: $black7-color;
         }
     }
     .accordion-button:not(.collapsed) {
         color: $black7-color;
         background: none;
         box-shadow: none;
         &::after {
             background-image: url("../img/svg/arrow-open.svg");
         }
     }
     .accordion-button:focus {
         box-shadow: none;
     }
     .accordion-item {
         border: none;
         border-bottom: 1px solid rgba(0, 0, 0, 0.06);
         padding-bottom: 10px;
         background: rgb(0 0 0 / 0%);
         &:last-child {
             border-bottom: none;
         }
     }
     .accordion-body {
         font-weight: 500;
         font-size: 16px;
         line-height: 33px;
         color: $gray2-color;
         padding: 0 0 20px;
     }
     .faq-dashboard-img {
         bottom: -68px;
     }
 }
 
 @media screen and (max-width: 991px) {
     .faq-dashboard-img {
         position: unset !important;
     }
     .mob-br {
         display: none;
     }
 }