form {
    .form-group {}
    .form-label {
        font-weight: 500;
        font-size: 18px;
        color: #FFFFFF;
        span {
            color: rgba(171, 171, 171, 0.14);
            &.mandatory {
                color: $blue-light-color;
            }
        }
    }
    .form-control {
        height: 57px;
        background: rgba(86, 78, 206, 0.2);
        border-radius: 28.5px;
        border: none;
        padding: 0px 30px;
        font-weight: 500;
        font-size: 16px;
        line-height: 37px;
        color: #FFFFFF;
        &:focus {
            color: #FFFFFF;
            box-shadow: 0px 0px 0px 1px $blue5-light-color;
        }
        &::placeholder {
            color: #454163;
        }
        &:hover,
        &:active,
        &:focus {
            background: rgba(86, 78, 206, 0.2);
            border: none;
            color: #FFFFFF;
        }
        // &[type="file"] {
        //     background: transparent;
        //     border: none;
        //     border-radius: 0px;
        //     &.custom-file-upload {
        //         border: 0px solid #ccc;
        //         display: inline-block;
        //         padding: 6px 12px;
        //         cursor: pointer;
        //     }
        // }
    }
    textarea {
        &.form-control {
            height: auto;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }
    .text-muted {
        font-size: 16px;
        color: rgba(255, 255, 255, 0.29) !important;
    }
    .marketPriceText {
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        color: rgba(255, 255, 255, 0.29);
      }
}

.react-select-container {
    min-height: 57px;
    // background-color: #454163;
    &.creation {
        .react-select__control {
            padding: 10px 5px 10px 0px !important;
            background: rgba(86, 78, 206, 0.2) !important;
            height: 100%;
            .react-select__value-container {
                padding: 0px 10px;
                .react-select__single-value {
                    color: #FFFFFF;
                }
            }
            .react-select__indicators {
                .react-select__indicator-separator {
                    opacity: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 2560px) {}

@media screen and (max-width: 1563px) {}

@media screen and (max-width: 1440px) {}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 600px) {}

@media screen and (max-width: 561px) {}