.buck-bundle-details {
    .detail-img {
        &.no-radius {
            .img {
                border-radius: 0px;
            }
        }
        .img {
            position: relative;
            // overflow: hidden;
            border-radius: 15px;
            .showOpenCards {
                position: relative;
                &::after {
                    content: "";
                    width: 50px;
                    height: 100%;
                    background-image: url('../img/getbucks/open-card-bg.png');
                    background-position: right bottom;
                    background-repeat: no-repeat;
                    /* margin-right: 0rem; */
                    z-index: inherit;
                    display: block;
                    right: -49px;
                    top: 0;
                    bottom: 0;
                    position: absolute;
                    z-index: 3;
                    background-size: 100% 100%;
                }
            }
            .detail-img-text {
                top: 0;
                padding: 1rem;
                position: absolute;
                &.bundle {
                    border-radius: 15px;
                    background: linear-gradient(0deg, #564ECE -23.58%, rgba(0, 0, 0, 0) 95.55%);
                    bottom: 0;
                    top: auto;
                }
                h3 {
                    font-weight: 600;
                    font-size: 25px;
                    color: #FFFFFF;
                    margin-bottom: 0;
                }
                hr {
                    color: #FFFFFF;
                }
                font-size: 14px;
                color: #D6D6D6;
            }
        }
    }
}

.upload-complete {
    position: relative;
    &::after {
        content: '';
        width: 100%;
        height: 4px;
        border-radius: 2px;
        background-color: #5a52ff;
        display: block;
        z-index: 1;
        top: -10px;
        position: absolute;
    }
}

.collection-heading {
    font-weight: normal;
    font-size: 21px;
    line-height: 38px;
    color: #FFFFFF;
    span {
        color: #5a52ff
    }
    .icon {
        margin-right: 0.5rem;
    }
    svg {
        path {
            fill: #5a52ff;
        }
    }
}

.list-slider {
    .collections-list {
        &.creator-hub {
            .slick-slider {
                width: 100%;
                padding-right: 60px;
                .collections-box {
                    padding: 0px 1rem;
                    // width: 170px !important;
                    .collections-img {
                        position: relative;
                        img {
                            width: 100% !important;
                        }
                        .hover {
                            opacity: 0;
                            position: absolute;
                            z-index: -1;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            background: rgba(86, 78, 206, 0.84);
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            align-content: center;
                            color: #FFFFFF;
                            a {
                                color: #FFFFFF;
                            }
                        }
                    }
                    .collection-text {}
                    &:hover {
                        .collections-img {
                            .hover {
                                opacity: 1;
                                z-index: 0;
                            }
                        }
                    }
                }
                .slick-list {
                    .slick-track {
                        .slick-slide {}
                    }
                }
                .slick-next::after {
                    right: -15px;
                    background-color: rgba(255, 255, 255, 0);
                }
            }
        }
    }
}

.buck-counts {
    font-weight: 500;
    font-size: 23px;
    line-height: 37px;
    text-align: right;
    color: rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 2560px) {}

@media screen and (max-width: 1563px) {}

@media screen and (max-width: 1440px) {}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 600px) {}

@media screen and (max-width: 561px) {
    .list-slider {
        .collections-list .slick-slider .collections-box {
            width: 350;
        }
        .collections-list {
            &.creator-hub {
                .slick-slider {
                    padding-right: 0px;
                    .collections-box {
                        padding: 0px 0.5rem;
                    }
                }
            }
        }
    }
}