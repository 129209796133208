.similar-artist-cards {
    .similarSlider {
        &.slick-slider {
            width: 100%;
            .slick-list {
                margin-right: 150px;
            }
            .slick-arrow {
                &.slick-next {
                    width: 150px;
                    text-align: center;
                    // background-image: url('../img/stardom.png');
                    &::after {
                        content: '';
                        background-image: url('../img/left-arrow.png');
                        background-position: center center;
                        background-repeat: no-repeat;
                        text-align: center;
                        margin-left: -30px;
                        line-height: 62px;
                    }
                    &:hover {
                        &::after {
                            background-color: #FFFFFF;
                        }
                    }
                }
            }
        }
        .slick-list {
            .slick-track {
                .slick-slide {
                    .similar-artist-card-box {
                        max-width: 75%;
                        background: #0B0D19;
                        border-radius: 19px;
                        overflow: hidden;
                        .pro-img-bg {
                            width: 100%;
                            img {
                                max-width: 100%;
                            }
                        }
                        .pro-img-cont {
                            position: relative;
                            width: 100%;
                            margin-bottom: 60px;
                            .pro-img {
                                border: 5px solid #0B0D19;
                                overflow: hidden;
                                border-radius: 75px;
                                position: absolute;
                                margin-top: -60px;
                                margin-left: -60px;
                                left: 50%;
                                right: 50%;
                                width: 120px;
                                height: 120px;
                                img {
                                    max-width: 100%;
                                }
                            }
                        }
                        .album-name {
                            font-size: 18px;
                            line-height: 38px;
                        }
                        .cta {
                            button {
                                font-weight: normal;
                                border-radius: 30px;
                                font-size: 16px;
                                padding: 10px 20px;
                            }
                        }
                    }
                }
            }
        }
        .slick-next::after {
            content: ">";
            // background-image: url('../../img/svg/WatchlistCard/logo-S.svg');
            position: absolute;
            top: 0;
            background: #fff;
            width: 60px;
            height: 60px;
            color: #564ECE;
            font-size: 53px;
            margin: auto;
            bottom: 0;
            border-radius: 100%;
            line-height: 54px;
        }
    }
    @media screen and (max-width:$md) {
        .similarSlider {
            .slick-dots {
                position: absolute;
                right: 0px;
                top: -25px;
            }
        }
    }
    @media screen and (max-width:$sm) {
        .similarSlider {
            &.slick-slider {
                .slick-list {
                    margin-right: 0px;
                    .slick-track {
                        .slick-slide {
                            .similar-artist-card-box {
                                max-width: 95%;
                                margin-left: 5%;
                            }
                        }
                    }
                }
                .slick-arrow {
                    &.slick-next {
                        display: none !important;
                    }
                }
            }
            .slick-dots {
                position: absolute;
                right: 0px;
                top: -25px;
            }
        }
    }
}