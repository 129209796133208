$rhs-width: calc(100% - 269px);
.calimed-tokens {
    .calimed-tokens-section {
        .head-filter {
            display: flex;
            justify-content: space-between;
            h4 {
                font-weight: normal;
                font-size: 20px;
                color: #ffffff;
                .sec-heading {
                    font-size: 20px;
                    color: #ffffff;
                }
                span {
                    color: #564ECE;
                }
            }
        }
        .cards-sec {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: flex-start;
            .cards-container {
                flex: 3;
                width: 100%;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: space-between;
                .card-box {
                    padding: 1rem;
                    background: #0B0D19;
                    border-radius: 24px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    .token-img {
                        margin-right: 1rem;
                        img {
                            height: 200px;
                        }
                    }
                    .details {
                        display: flex;
                        width: 100%;
                        flex-direction: column;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        .by {
                            font-weight: normal;
                            font-size: 16px;
                            color: rgba(255, 255, 255, 0.28);
                        }
                        .title {
                            font-weight: normal;
                            font-size: 21px;
                            color: #FFFFFF;
                        }
                        .claimed {
                            font-weight: normal;
                            font-size: 16px;
                            color: #ABABAB;
                            .token-rarity {
                                font-weight: normal;
                                font-size: 16px;
                                margin-left: 10px;
                                color: rgba(37, 255, 176, 0.88);
                                svg {
                                    path {
                                        fill: rgba(37, 255, 176, 0.88);
                                    }
                                }
                            }
                        }
                        .token-type {
                            display: flex;
                            justify-content: space-between;
                            div {
                                background: #564ECE;
                                border-radius: 20.5px;
                                width: 40px;
                                height: 40px;
                                display: flex;
                                align-content: center;
                                align-items: center;
                                justify-content: center;
                                margin: 0px 0.25rem;
                                svg {
                                    width: 18px;
                                    height: 18px;
                                }
                            }
                        }
                        .req {
                            font-style: italic;
                            font-weight: normal;
                            font-size: 16px;
                            color: #564ECE;
                        }
                        button {
                            border-radius: 2rem;
                            padding: 0.75rem 1.5rem;
                            font-size: 16px;
                            font-weight: normal;
                        }
                    }
                }
            }
            .filter-sec {
                flex: 1;
                // width: 350px;
                // max-width: 350px;
                padding: 1rem;
                .head-filter {
                    justify-content: space-between;
                    h4 {
                        font-weight: normal;
                        font-size: 20px;
                        color: #ffffff;
                        .sec-heading {
                            font-size: 20px;
                            color: #ffffff;
                        }
                        span {
                            color: #564ECE;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 2560px) {}
        @media screen and (max-width: 1563px) {}
        @media screen and (max-width: 1440px) {}
        @media screen and (max-width: 1024px) {}
        @media screen and (max-width: 1024px) {}
        @media screen and (max-width: 768px) {
            .cards-sec {
                flex-direction: column-reverse;
                .cards-container {
                    .card-box {
                        .token-img {
                            img {}
                        }
                        .details {}
                    }
                }
                .filter-sec {
                    width: 100% !important;
                    max-width: 100% !important;
                }
            }
        }
        @media screen and (max-width: 600px) {}
        @media screen and (max-width: 561px) {
            .cards-sec {
                flex-direction: column-reverse;
                .cards-container {
                    .card-box {
                        flex-direction: column;
                        .token-img {
                            margin-bottom: 1rem;
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                        .details {}
                    }
                }
                .filter-sec {
                    width: 100% !important;
                    max-width: 100% !important;
                }
            }
        }
    }
}

// .react-select-container {
//     max-width: 400px;
//     // background-color: red;
//     //     border: 0px;
//     //     outline: 0px;
//     background: rgba(196, 196, 196, 0);
//     // overflow: hidden;
//     .react-select__control {
//         background-color: blue;
//         border-radius: 26px;
//         background-color: #17181D;
//         border-color: hsla(0, 100%, 50%, 0);
//         .react-select__value-container {
//             background-color: #17181D;
//             border-top-left-radius: 26px;
//             border-bottom-left-radius: 26px;
//             padding: 0.25rem;
//             display: flex;
//             flex-direction: row;
//             flex-wrap: nowrap;
//             // overflow-x: scroll;
//             .react-select__multi-value {
//                 background-color: #564ECE;
//                 padding: 0.25rem 1rem 0.25rem 0.75rem;
//                 border-radius: 20.5px;
//                 min-width: 100px;
//                 position: relative;
//                 .react-select__multi-value__label {
//                     min-width: 100%;
//                     box-sizing: border-box;
//                     color: #ffffff;
//                 }
//                 .react-select__multi-value__remove {
//                     position: absolute;
//                     right: 5px;
//                     top: 0px;
//                     bottom: 0px;
//                     color: #fff;
//                     // background: aqua;
//                     height: 100%;
//                     &:hover {
//                         color: #fff;
//                         background: transparent;
//                     }
//                 }
//             }
//             .react-select__placeholder {
//                 margin-left: 1rem;
//             }
//             .react-select__input-container {
//                 min-width: 100px;
//             }
//         }
//     }
//     .react-select__menu {
//         backdrop-filter: blur(20px);
//         border-radius: 16px;
//         background: rgb(48, 48, 48);
//         overflow: hidden;
//         .react-select__menu-list {
//             // background-color: yellow;
//         }
//         .react-select__option {
//             // background-color: orange;
//             background: rgba(0, 0, 0, 0);
//             color: #FFFFFF;
//             padding: 1rem 1.5rem;
//             margin: 0px;
//             &:hover {
//                 background: #564ECE;
//             }
//         }
//     }
// }
.react-select-container {
    &.css-b62m3t-container {
        .css-1s2u09g-control,
        .css-1pahdxg-control {
            background: rgba(196, 196, 196, 0.1);
            border-radius: 26px;
            border: none;
            padding: 6px 5px 6px 0px;
            .css-14el2xx-placeholder {
                padding: 0px 12px;
            }
            .css-1rhbuit-multiValue {
                background: #564ECE;
                border-radius: 16.5px;
                color: #fff;
                padding: 3px 5px;
                svg {
                    width: 15px;
                    height: 15px;
                }
                .css-12jo7m5 {
                    font-weight: normal;
                    font-size: 12px;
                    color: #FFFFFF;
                }
                .css-xb97g8:hover {
                    background: none;
                    color: #ffffff;
                    border-radius: 100%;
                }
            }
            .css-g1d714-ValueContainer {
                // flex-wrap: nowrap;
            }
        }
        .css-26l3qy-menu {
            background: rgba(24, 22, 51, 0.67) !important;
            backdrop-filter: blur(20px);
            border-radius: 16px;
            padding: 0 !important;
            color: #fff;
            .css-4ljt47-MenuList {
                background: transparent !important;
                font-weight: normal;
                font-size: 16px;
                color: #fff;
                padding: 0 !important;
                .css-1n7v3ny-option {
                    background: transparent !important;
                    color: #564ECE !important;
                    font-weight: normal;
                    font-size: 16px;
                    svg {
                        path {
                            fill: #564ECE;
                        }
                    }
                }
                .icon-menusx {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.07) !important;
                    padding: 0px 20px 14px 20px !important;
                }
            }
            .icon-menusx {
                border-bottom: 1px solid rgba(255, 255, 255, 0.07) !important;
                padding: 0px 20px 14px 20px !important;
                color: #fff;
            }
            .css-1n7v3ny-option {
                background: transparent !important;
                color: #564ECE !important;
                font-weight: normal;
                font-size: 16px;
                svg {
                    path {
                        fill: #564ECE;
                    }
                }
                .icon-menusx {
                    color: #564ECE;
                }
            }
        }
    }
    &.converter {
        &.css-b62m3t-container {
            .css-1s2u09g-control,
            .css-1pahdxg-control {
                background: rgba(196, 196, 196, 0.0);
                .css-14el2xx-placeholder {}
                .css-1rhbuit-multiValue {
                    svg {}
                    .css-12jo7m5 {}
                    .css-xb97g8:hover {}
                }
                .css-g1d714-ValueContainer {
                    // flex-wrap: nowrap;
                }
            }
        }
    }
}