$rhs-width: calc(100% - 269px);
.access-token-details {
    margin-top: 56px;
    .access-token-details-section {
        .sec-heading {
            margin-bottom: 4rem;
            h4 {
                font-weight: normal;
                font-size: 21px;
                color: #FFFFFF;
                span {
                    color: #564ECE;
                }
            }
        }
        .token-details {
            display: flex;
            .left-sec {
                padding: 1rem;
                position: relative;
                margin-left: -4rem;
                margin-top: -3rem;
                .let-meknow-card {
                    flex: 1.25;
                    min-width: 250px;
                    .card {
                        background: linear-gradient( 71.45deg, rgba(13, 11, 33, 0.54) 14.26%, rgba(86, 78, 206, 0.1242) 113.99%);
                        // background: linear-gradient(71.45deg, rgba(13, 11, 33, 0.54) 14.26%, rgba(86, 78, 206, 0.1242) 113.99%);
                        border: 2.17765px solid #564ECE;
                        box-sizing: border-box;
                        backdrop-filter: blur(5.21937px);
                        border-radius: 21.7765px;
                    }
                    h4.card-name {
                        font-weight: normal;
                        font-size: 15.6581px;
                        line-height: 22px;
                        color: #FFFFFF;
                    }
                    h5 {
                        font-weight: 300;
                        font-size: 13.9183px;
                        color: rgba(255, 255, 255, 0.28);
                    }
                    .card-addr {
                        &.card-text {
                            font-size: 10px;
                            letter-spacing: 1px;
                        }
                    }
                    .categoryType {
                        margin-top: 1rem;
                        span {
                            font-weight: 300;
                            font-size: 15px;
                            color: #FFFFFF;
                        }
                        .text-start {
                            label {
                                font-weight: 300;
                                font-size: 10px;
                                line-height: 16px;
                                color: rgba(255, 255, 255, 0.5);
                            }
                        }
                        .text-end {
                            text-align: left!important;
                            label {
                                font-weight: 300;
                                font-size: 10px;
                                color: rgba(255, 255, 255, 0.5);
                            }
                            div {}
                        }
                    }
                }
            }
            .right-sec {
                .v-text {
                    position: absolute;
                    bottom: 2rem;
                    left: 3rem;
                    font-weight: normal;
                    font-size: 21px;
                    line-height: 38px;
                    letter-spacing: 0.315em;
                    color: #ABABAB;
                    transform: rotate(-90deg);
                    transform-origin: bottom left;
                }
                .row-one {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    font-weight: normal;
                    font-size: 21px;
                    .hash-no {
                        font-size: inherit;
                        color: #ABABAB;
                    }
                    .out-of {
                        font-size: inherit;
                        color: #ABABAB;
                        margin-left: 2rem;
                    }
                    .rairty {
                        color: #25FFB0E0;
                        svg {
                            margin: 0px 0.5rem;
                            path {
                                fill: #25FFB0E0;
                            }
                        }
                    }
                }
                .title {
                    font-weight: bold;
                    font-size: 30px;
                    color: #FFFFFF;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: center;
                    align-content: center;
                    .claimed {
                        font-weight: normal !important;
                        font-size: 15px;
                        line-height: 38px;
                        color: #ABABAB;
                    }
                }
                .creator {
                    font-weight: 500;
                    font-size: 16px;
                    color: #ABABAB;
                    span {
                        color: #564ECE
                    }
                }
                .token-type {
                    >div {
                        display: inline-block;
                        margin-right: 1rem;
                        padding: 0.5rem 1.25rem;
                        background: #564ECE;
                        border-radius: 20.5px;
                        color: #FFFFFF;
                        svg {
                            width: 18px;
                        }
                    }
                }
                .text {
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 27px;
                    /* or 172% */
                    color: #ABABAB;
                    h4 {
                        font-size: 21px;
                        font-weight: 600;
                        color: #564ECE;
                    }
                }
                .unlock-access {
                    position: relative;
                    .video-sec {
                        .video-boxs-Tutorial {
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                    .watch-btn {
                        display: inline-block;
                        color: #FFFFFF;
                        position: relative;
                        z-index: 5;
                        top: -1rem;
                        margin: 0 auto;
                        text-align: center;
                        width: 100%;
                        button {
                            position: relative;
                            padding: 0.5rem 1rem;
                            background: linear-gradient(158.07deg, #564ECE 6.22%, #837BFF 92.27%);
                            border-radius: 11px;
                        }
                    }
                }
            }
        }
        .token-info {
            font-weight: 500;
            font-size: 16px;
            color: #FFFFFF;
            padding: 4rem;
            .info-title {
                font-weight: normal;
                font-size: 21px;
                line-height: 38px;
                color: #ABABAB;
                margin-bottom: 1rem;
            }
            .info-row {
                display: flex;
                justify-content: space-between;
                margin-bottom: 1rem;
            }
        }
        .video-images {
            .gal-images {
                display: flex;
                justify-content: space-between;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                &.details {
                    >div {
                        &:nth-child(1) {
                            flex: 1;
                        }
                        &:nth-last-child(1) {
                            flex: 2;
                        }
                    }
                }
            }
            .video-sec {
                .video-boxs-Tutorial {
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
        .faq {
            .accordion-button:not(.collapsed)::after {
                // background-image: url();
                transform: rotate( -180deg);
            }
            .faq-staking-payments {
                .accordion {
                    .accordion-item {
                        .accordion-header {
                            .accordion-button {
                                background-color: #F2F2F2;
                                font-weight: 500;
                                &:not(.collapsed) {
                                    background-color: #F2F2F2;
                                }
                            }
                        }
                        .accordion-collapse .accordion-body {
                            background-color: #F2F2F2;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 2560px) {}
        @media screen and (max-width: 1563px) {}
        @media screen and (max-width: 1440px) {}
        @media screen and (max-width: 1024px) {}
        @media screen and (max-width: 1024px) {}
        @media screen and (max-width: 768px) {
            .token-details {
                flex-direction: column;
                flex-wrap: nowrap;
                align-content: center;
                align-items: stretch;
                .left-sec {
                    padding: 0px 2rem;
                    position: relative;
                    margin-left: 0px;
                    margin-top: -2rem;
                }
                .right-sec {
                    .row-one {
                        flex-direction: column;
                    }
                    .title {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        .claimed {
                            font-weight: normal !important;
                            font-size: 15px;
                            line-height: 38px;
                            color: #ABABAB;
                        }
                    }
                    .creator {}
                    .token-type {
                        >div {
                            margin-bottom: 0.5rem;
                        }
                    }
                    .text {}
                    .unlock-access {
                        position: relative;
                        .video-sec {
                            .video-boxs-Tutorial {
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                        }
                        .watch-btn {
                            display: inline-block;
                            color: #FFFFFF;
                            position: unset;
                            z-index: 5;
                            top: 1rem;
                            margin: 1rem auto;
                            text-align: center;
                            width: 100%;
                        }
                    }
                }
            }
            .token-info {
                font-weight: 500;
                font-size: 16px;
                color: #FFFFFF;
                padding: 2rem;
                .info-title {
                    font-weight: normal;
                    font-size: 21px;
                    line-height: 38px;
                    color: #ABABAB;
                    margin-bottom: 1rem;
                }
                .info-row {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 1rem;
                }
            }
            .video-images {
                .gal-images {
                    flex-direction: row;
                    >div {
                        flex: 1;
                        img {
                            width: 100%;
                            height: auto;
                            object-fit: cover;
                        }
                    }
                }
                .video-sec {
                    .video-boxs-Tutorial {
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 600px) {
            .token-details {
                flex-direction: column;
                flex-wrap: nowrap;
                align-content: center;
                align-items: stretch;
                .left-sec {
                    padding: 0px 2rem;
                    position: relative;
                    margin-left: 0px;
                    margin-top: -2rem;
                }
                .right-sec {
                    .v-text {
                        display: none;
                    }
                    .row-one {
                        flex-direction: column;
                    }
                    .title {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        .claimed {
                            font-weight: normal !important;
                            font-size: 15px;
                            line-height: 38px;
                            color: #ABABAB;
                        }
                    }
                    .creator {}
                    .token-type {
                        >div {
                            margin-bottom: 0.5rem;
                        }
                    }
                    .text {}
                    .unlock-access {
                        position: relative;
                        .video-sec {
                            .video-boxs-Tutorial {
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                        }
                        .watch-btn {
                            display: inline-block;
                            color: #FFFFFF;
                            position: unset;
                            z-index: 5;
                            top: 1rem;
                            margin: 1rem auto;
                            text-align: center;
                            width: 100%;
                            // button {
                            //     position: relative;
                            //     padding: 0.5rem 1rem;
                            //     background: linear-gradient(158.07deg, #564ECE 6.22%, #837BFF 92.27%);
                            //     border-radius: 11px;
                            // }
                        }
                    }
                }
            }
            .token-info {
                font-weight: 500;
                font-size: 16px;
                color: #FFFFFF;
                padding: 2rem;
                .info-title {
                    font-weight: normal;
                    font-size: 21px;
                    line-height: 38px;
                    color: #ABABAB;
                    margin-bottom: 1rem;
                }
                .info-row {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 1rem;
                }
            }
            .video-images {
                .gal-images {
                    flex-direction: column;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .video-sec {
                    .video-boxs-Tutorial {
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 561px) {}
    }
}