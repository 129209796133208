.overview-stats {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  //   justify-content: space-between;
  justify-content: center;
  //   align-items: stretch;
  align-items: baseline;
  .card {
    width: 25% !important;
    position: relative;
    padding: 24px 16px;
    margin: 0.5rem;
    border-radius: 12px;
    background: #0000;
    // background: rgba(239, 233, 255, 0.03);
    &:nth-child(1) {
      margin-left: 0px;
    }
    &:nth-last-child(1) {
      margin-right: 0px;
      width: 27% !important;
    }
    .info {
      position: absolute;
      right: 8px;
      top: 8px;
      svg {
        width: 18px;
      }
    }
    .amount {
      font-style: normal;
      //  font-weight: 500;
      font-weight: 600;
      font-size: 32px;
      //   line-height: 42px;
      line-height: 33px;
      color: #ffffff;
    }
    span {
      font-style: normal;
      //   font-weight: 500;
      font-weight: 300;
      font-size: 14px;
      padding-top: 10px;
      color: #ffffff;
      svg {
        margin-right: 5px;
        path {
          fill: #ffffff;
        }
      }
    }
    &.total-royalties-received {
      flex: 3;
    }
    &.cashed-out-royalties {
      flex: 3;
    }
    &.available-royalties {
      .info {
        svg {
          path {
            fill: #ffffff;
          }
        }
      }
      //   flex: 2;
      height: 75%;
      width: 25%;
      background: linear-gradient(
        83.55deg,
        #fc1885 -12.13%,
        #9b41f6 -12.12%,
        #561eef 111.34%
      );
    }
    &.token-growth {
      .info {
        svg {
          path {
            fill: #ffffff;
          }
        }
      }
      flex: 2;
      background: linear-gradient(
        83.55deg,
        #fc1885 -12.13%,
        #9b41f6 -12.12%,
        #561eef 111.34%
      );
    }
  }
  &.royalties {
    .card {
      &.total-royalties-received {
        background: linear-gradient(
          83.55deg,
          #fc1885 -12.13%,
          #9b41f6 -12.12%,
          #561eef 111.34%
        );
        flex: 2;
        .info {
          svg {
            path {
              fill: #ffffff;
            }
          }
        }
      }
      &.cashed-out-royalties {
        background: linear-gradient(
          83.55deg,
          #fc1885 -12.13%,
          #9b41f6 -12.12%,
          #561eef 111.34%
        );
        flex: 2;
        .info {
          svg {
            path {
              fill: #ffffff;
            }
          }
        }
      }
      &.available-royalties {
        background: rgba(239, 233, 255, 0.03);
        .info {
          svg {
            path {
              fill: #ffffff;
            }
          }
        }
        flex: 3;
      }
      &.token-growth {
        background: rgba(239, 233, 255, 0.03);
        .info {
          svg {
            path {
              fill: #ffffff;
            }
          }
        }
        flex: 3;
      }
    }
  }
  &.indivisual-cards {
    .card {
      flex: 3;
      background: rgba(239, 233, 255, 0.03);
      .info {
        svg {
          path {
            fill: #ffffff;
          }
        }
      }
      &:nth-child(4) {
        background: linear-gradient(
          83.55deg,
          #fc1885 -12.13%,
          #9b41f6 -12.12%,
          #561eef 111.34%
        );
        flex: 2;
        .info {
          svg {
            path {
              fill: #ffffff;
            }
          }
        }
      }
      &:nth-child(3) {
        background: linear-gradient(
          83.55deg,
          #fc1885 -12.13%,
          #9b41f6 -12.12%,
          #561eef 111.34%
        );
        .info {
          svg {
            path {
              fill: #ffffff;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    flex-wrap: nowrap;
    flex-direction: column;
    .card {
      //  &:nth-child(1) {
      //      margin-left: 0.5rem;
      //  }
      //  &:nth-last-child(1) {
      //      margin-left: 0.5rem;
      //  }
      margin-left: 0px;
      margin-right: 0px;
      &.total-royalties-received {
        flex: 1;
        width: 100%;
      }
      &.cashed-out-royalties {
        flex: 1;
        width: 100%;
      }
      &.available-royalties {
        flex: 1;
        width: 100%;
      }
      &.token-growth {
        flex: 1;
        width: 100%;
      }
    }
  }
}
