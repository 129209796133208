.bucks-complete-transaction {
    .page-heading {
        color: #FFFFFF;
        display: flex;
        flex-direction: row;
        margin-top: 100px;

        h3 {
            font-weight: normal;
            font-size: 21px;
            color: #FFFFFF;
            margin-left: 10px;
        }

        p {
            margin-left: 10px;
            font-weight: normal;
            font-size: 16px;
            color: #ffffff80;
        }
    }

    .banner-collection {
        button {
            &.rounded {
                border-radius: 50px !important;
            }
        }
    }

    // SELECT NFT SECTION
    .select-nft {
        background-image: url('../img/svg/hassle-bg-A.svg');
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-around;

        .shadow-hassle-img {
            z-index: -1;

            svg {
                width: 100%;
            }
        }

        .filter {
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-content: center;
            align-items: center;

            .input {
                &.search {
                    position: relative;

                    input {
                        padding-left: 3rem;
                    }

                    .icon {
                        padding: 0.5rem 1rem;
                        position: absolute;
                        z-index: 1;
                    }
                }
            }
        }

        .box {
            margin: 1rem;
            flex: 1;
            background-color: #0B0D19;

            h3 {
                font-weight: normal;
                font-size: 21px;
                color: #FFFFFF;
            }

            .sub-heading {
                font-weight: normal;
                font-size: 16px;
                line-height: 21px;
                /* identical to box height, or 131% */
                color: rgba(255, 255, 255, 0.66);
            }

            //CARDS
            .cards-holder {
                height: 550px;
                overflow-y: scroll;

                .card {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    background: #141231;
                    border-radius: 24px;
                    padding: 1rem;
                    margin: 14px 0px;
                    margin-right: 0.5rem;

                    .buck-img {
                        img {
                            width: 80px;
                        }
                    }

                    .card-details {
                        padding-left: 1rem !important;
                        width: 100%;

                        .card-title-qty-price {
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            align-items: stretch;
                            height: 100%;

                            .check-mark {
                                border-radius: 15px;
                                background-color: hsla(0, 0%, 100%, 0.259);
                                position: absolute;
                                width: 30px;
                                height: 30px;
                                top: 0;
                                right: 0;
                                z-index: 10;

                                &:active {
                                }
                            }

                            .active {
                                background: #FFFFFF;
                                background-image: url("../img/svg/selected.png");
                                background-position: center center;
                                background-repeat: no-repeat;
                            }

                            .card-title {
                                font-weight: normal;
                                font-size: 18px;
                                line-height: 22px;
                                color: #FFFFFF;
                                margin-bottom: 1rem;
                            }

                            .card-qty {
                                font-weight: normal;
                                font-size: 16px;
                                color: rgba(255, 255, 255, 0.5);

                                >span {
                                    padding: 0.3rem 1.5rem;
                                    background: rgba(196, 196, 196, 0.1);
                                    border-radius: 26px;
                                    font-weight: normal;
                                    font-size: 16px;
                                    text-align: center;
                                    color: #8781E0;
                                    margin-right: 1rem;
                                }
                            }

                            .select-qty {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: nowrap;
                                justify-content: space-between;
                                align-items: center;
                                align-content: center;
                                font-weight: normal;
                                font-size: 18px;
                                color: #FFFFFF;

                                >span {
                                    margin-left: 1rem;
                                    display: inline-block;
                                }

                                .select-input {
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    justify-content: flex-start;
                                    align-items: center;
                                    align-content: center;

                                    >span {
                                        margin-right: 1rem;
                                    }
                                }

                                .cta {
                                    button {
                                        &.view-details-btn {
                                            padding: 0.5rem 1rem !important;
                                            font-weight: normal !important;
                                            font-size: 16px;
                                            text-align: center;
                                            color: #FFFFFF;
                                            border-radius: 1rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .react-select-container {

            //     opacity: 1;
            &.filter {
                width: 150px;
            }

            .react-select__control {
                //         min-height: 20px;
                //         opacity: 1;
                //         background: rgba(229, 229, 229, 0.13);
                padding: initial;

                .react-select__value-container {

                    //             opacity: 1;
                    //             background: transparent;
                    //             display: grid;
                    //             padding: 0rem 0.5rem;
                    .react-select__single-value {
                        color: #FFFFFF !important;
                        //                 font-weight: 500;
                        //                 font-size: 20px;
                    }

                    //             .react-select__input-container {
                    //                 margin: 0px;
                    //             }
                }

                .react-select__indicator-separator {
                    //             display: none;
                }

                .react-select__indicator {
                    //             padding: 4px;
                }
            }

            &.converter {
                .react-select__control {
                    padding: initial;

                    .react-select__value-container {
                        background: rgba(196, 196, 196, 0) !important;

                        .react-select__single-value {
                            color: #FFFFFF;
                        }
                    }

                    .react-select__indicator-separator {}

                    .react-select__indicator {}
                }
            }

            &.creation {
                .react-select__control {
                    .react-select__value-container {
                        &.react-select__value-container--has-value {
                            color: #FFFFFF;

                            .react-select__single-value {
                                color: #FFFFFF !important;
                            }
                        }
                    }
                }
            }
        }
    }

    //SELECTED SECTION
    .selected-list {
        .buck-imgs {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;

            .buck-img {
                margin-right: 0.5em;

                img {
                    width: 80px;
                }
            }
        }

        .selected-buck-details {
            padding-top: 1rem;
            border-bottom: 1px solid rgba(255, 255, 255, 0.32);

            .details {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;
                align-content: center;
                margin-bottom: 1rem;

                .card-title {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    color: #FFFFFF;
                    max-width: 50%;
                }

                .card-qty-amount {
                    width: 40%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: center;
                    align-content: center;

                    .card-qty {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        color: rgba(255, 255, 255, 0.5);
                    }

                    .amount {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        color: #564ECE;
                    }
                }
            }
        }

        .total-sec {
            .total {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;
                align-content: center;
                font-weight: normal;
                font-size: 16px;
                color: rgba(255, 255, 255, 0.66);

                span {
                    color: #564ECE;
                }
            }

            .converting-to {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: center;
                align-content: center;
            }
        }

        .check {
            font-size: 16px;
            line-height: 21px;
            color: #FFFFFF;

            .form-check {
                .form-check-input {
                    background-color: transparent !important;
                    border: 1px solid #564ECE;
                    width: 20px;
                    height: 20px;
                    border-radius: 0px;
                    padding: 4px;

                    &:checked {}
                }

                .form-check-label {
                    padding-left: 0.5rem;
                }
            }
        }
    }

    .devider-holder {
        // margin-top: 150px;
    }

    @media screen and (max-width: 2560px) {}

    @media screen and (max-width: 1563px) {}

    @media screen and (max-width: 1440px) {}

    @media screen and (max-width: 1024px) {}

    @media screen and (max-width: 1024px) {}

    @media screen and (max-width: 768px) {
        .select-nft {
            flex-direction: column;

            .filter {
                // display: flex;
                flex-direction: column;

                // flex-wrap: nowrap;
                // justify-content: flex-start;
                // align-content: center;
                // align-items: center;
                >div {
                    margin-bottom: 1rem;
                }

                .input {
                    &.search {
                        position: relative;

                        input {
                            padding-left: rem;
                        }

                        .icon {
                            padding: 0.5rem 1rem;
                            position: absolute;
                            z-index: 1;
                        }
                    }
                }
            }
        }

        .selected-list {}

        .devider-holder {
            .shape-bg-section {
                // margin-top: -115px;
                // height: 230px;
            }
        }
    }

    @media screen and (max-width: 600px) {}

    @media screen and (max-width: 561px) {
        .select-nft {
            flex-direction: column;

            .filter {
                // display: flex;
                flex-direction: column;

                // flex-wrap: nowrap;
                // justify-content: flex-start;
                // align-content: center;
                // align-items: center;
                >div {
                    margin-bottom: 1rem;
                }

                .input {
                    &.search {
                        position: relative;

                        input {
                            padding-left: rem;
                        }

                        .icon {
                            padding: 0.5rem 1rem;
                            position: absolute;
                            z-index: 1;
                        }
                    }
                }
            }

            .box {
                margin: 1rem;
                flex: 1;
                background-color: #0B0D19;

                h3 {
                    font-weight: normal;
                    font-size: 21px;
                    color: #FFFFFF;
                }

                .sub-heading {
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 21px;
                    /* identical to box height, or 131% */
                    color: rgba(255, 255, 255, 0.66);
                }

                //CARDS
                .cards-holder {
                    height: 550px;
                    overflow-y: scroll;

                    .card {
                        flex-direction: column;

                        .buck-img {
                            text-align: center;
                            margin-bottom: 1rem;

                            img {
                                width: 100px;
                            }
                        }

                        .card-details {
                            padding-left: 1rem !important;
                            width: 100%;

                            .card-title-qty-price {
                                position: relative;
                                display: flex;
                                flex-direction: column;
                                flex-wrap: nowrap;
                                justify-content: space-between;
                                align-items: stretch;
                                height: 100%;

                                .check-mark {
                                    border-radius: 15px;
                                    background-color: hsla(0, 0%, 100%, 0.259);
                                    position: absolute;
                                    width: 30px;
                                    height: 30px;
                                    top: 0;
                                    right: 0;
                                    z-index: 10;

                                    &:active {}
                                }

                                .card-title {
                                    margin-right: 1.5rem;
                                }

                                .card-qty {
                                    margin-bottom: 1rem;
                                    text-align: center;

                                    >span {}
                                }

                                .select-qty {
                                    flex-direction: column;

                                    >span {}

                                    .select-input {
                                        margin-bottom: 1rem;
                                    }

                                    .cta {
                                        button {
                                            &.view-details-btn {
                                                padding: 0.5rem 1rem !important;
                                                font-weight: normal !important;
                                                font-size: 16px;
                                                text-align: center;
                                                color: #FFFFFF;
                                                border-radius: 1rem;
                                            }
                                        }
                                    }

                                    // .react-select-container {
                                    //     width: 70px;
                                    //     opacity: 1;
                                    //     .react-select__control {
                                    //         min-height: 20px;
                                    //         opacity: 1;
                                    //         background: rgba(229, 229, 229, 0.13);
                                    //         .react-select__value-container {
                                    //             opacity: 1;
                                    //             background: transparent;
                                    //             display: grid;
                                    //             padding: 0rem 0.5rem;
                                    //             .react-select__single-value {
                                    //                 color: #FFFFFF;
                                    //                 font-weight: 500;
                                    //                 font-size: 20px;
                                    //             }
                                    //             .react-select__input-container {
                                    //                 margin: 0px;
                                    //             }
                                    //         }
                                    //         .react-select__indicator-separator {
                                    //             display: none;
                                    //         }
                                    //         .react-select__indicator {
                                    //             padding: 4px;
                                    //         }
                                    //     }
                                    // }
                                }
                            }
                        }
                    }
                }
            }
        }

        .selected-list {}
    }
}

.react-select-container {
    &.converter {
        .react-select__control {
            padding: initial;

            .react-select__value-container {
                background: rgba(196, 196, 196, 0) !important;

                .react-select__single-value {
                    color: #564ECE !important;
                }
            }

            .react-select__indicator-separator {
                background-color: hsla(0, 0%, 80%, 0) !important;
            }

            .react-select__dropdown-indicator {
                padding-left: 0px !important;
            }

            .react-select__indicator {}
        }
    }
}