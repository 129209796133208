.collection-creation {
    .blurr-bg2 {
        background-position: left center;
        &::after {
            background-image: url('../img/blurr-bg.png');
            content: "";
            opacity: 0.5;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            z-index: -1;
            background-position: -80% 10%;
            background-size: 85%;
            background-repeat: no-repeat;
        }
    }
}

.form-group {
    .thumbImg {
        display: flex;
        align-content: center;
        .isImg {
            display: flex;
            align-content: center;
            align-items: center;
            color: #fff;
            div {
                margin: 1rem;
            }
            img {
                width: 60px;
                border-radius: 4px;
                overflow: hidden;
            }
        }
    }
}

.access-token-img {
    height: 100%;
    .buck-collecton-placehlder {
        height: 100%;

        .isImg img {
            height: 100%;
        }
    }
}

.buck-collecton-placehlder {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    // background: #ccc;
    position: relative;
    height: 100%;
    >div {
        position: relative;
        img {
            width: 317px;
            height: 636px;
            border-radius: 17px;
        }
        &.isImg {
            &::after {
                background-image: url('../img/getbucks/open-card-bg.png');
            }
        }
        &::after {
            content: '';
            width: 50px;
            height: 100%;
            background-image: url('../img/placeholder-bg.png');
            background-position: right bottom;
            background-repeat: no-repeat;
            /* margin-right: 0rem; */
            z-index: inherit;
            // background-color: azure;
            display: block;
            right: -49px;
            top: 0;
            bottom: 0;
            position: absolute;
            z-index: 3;
            background-size: 100% 100%;
        }
    }
    &.bucks {
        >div {
            img {
                border-radius: 0px;
            }
            &.isImg {
                &::after {
                    background-image: none;
                }
            }
            &::after {
                content: '';
                width: 0px;
                height: 100%;
                background-image: none;
                background-position: right bottom;
                background-repeat: no-repeat;
                /* margin-right: 0rem; */
                z-index: inherit;
                // background-color: azure;
                display: block;
                right: -50px;
                top: 0;
                bottom: 0;
                position: absolute;
                z-index: 3;
                background-size: 100% 100%;
            }
        }
    }
    &.bunlde {
        div {
            min-width: 400px;
            max-width: 465px;
            min-height: 700px;
            max-height: 76765px;
        }
        img {
            width: 467px;
            height: 767px;
        }

    }
}

.edit-field-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: flex-start;
    .field {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
        background: #04050A;
        border-radius: 34px;
        justify-content: space-between;
        div {
            flex: 1;
        }
        .lable {
            font-weight: 500;
            font-size: 18px;
            color: #564ECE;
        }
        .value {
            font-weight: 500;
            font-size: 21px;
            color: #fff;
        }
    }
    .edit-btn {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        color: #564ECE;
        a {
            margin: 0px 5px;
        }
    }
}

//-----------NFTbucks  css ---------------//
.review-collection {
    .detail-img {
        margin-left: 50px;
        margin-right: -50px;
        z-index: 1;
        height: 700px;
        img {
            border-radius: 12px;
            overflow: hidden;
            width: 350px;
            height: 100%;
        }
        &.bucks {
            .buck-collecton-placehlder {
                img {
                    width: 300px;
                }
            }
        }
    }
    .text-detail {
        background: #0B0D19;
        border-radius: 24px;
        padding: 40px 50px 40px 140px;
        margin-top: 100px;
        h6 {
            font-weight: 500;
            font-size: 16px;
            color: $gray6-color;
        }
        ul li {
            list-style: none;
        }
        h5 {
            font-weight: normal;
            font-size: 21px;
            color: $gray6-color;
        }
        .price-detail {
            font-weight: normal;
            font-size: 21px;
            color: $blue2-light-color;
        }
        .auth-detail {
            font-weight: 500;
            font-size: 16px;
            color: $gray6-color;
        }
        h1 {
            font-weight: normal;
            font-size: 32px;
            color: $white-color;
        }
        .view-bundle-btn {
            background: rgba(196, 196, 196, 0.1);
            border-radius: 45px;
            font-weight: 500;
            font-size: 16px;
            color: rgba(255, 255, 255, 0.5);
            border: none;
            padding: 13px 30px;
            text-decoration: none;
            &:hover {
                border: none;
                background: $blue-light-color;
                color: $white-color;
            }
        }
        p {
            font-weight: normal;
            font-size: 16px;
            line-height: 38px;
            color: $gray6-color;
        }
        .cta {
            .buck-typen-date {
                display: flex;
                .type {
                    font-size: 16px;
                    color: #564ECE;
                }
                .date {
                    font-weight: 500;
                    font-size: 16px;
                    color: rgba(255, 255, 255, 0.5);
                }
            }
        }
    }
    .standard-bucks {
        background: #0B0D19;
        border-radius: 24px;
        h2 {
            font-weight: normal;
            font-size: 21px;
            color: $white-color;
            span {
                color: $blue2-light-color;
            }
        }
        .slick-slider {
            .slick-track {
                margin-left: 0;
                float: left;
            }
            .slick-dots {
                right: -40px;
                top: -60px;
            }
            .collections-box {
                width: 192.91px;
                overflow: hidden;
                .img-hover-click {
                    background: linear-gradient(162.24deg, rgba(157, 153, 208, 0.82) 3.31%, rgba(64, 51, 255, 0.82) 97.08%);
                    opacity: 0;
                    transition: 0.2s;
                    a {
                        text-decoration: none;
                        p {
                            font-weight: 500;
                            font-size: 16px;
                            color: $white-color;
                        }
                    }
                }
                &:hover {
                    .img-hover-click {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 2560px) {}

@media screen and (max-width: 1563px) {}

@media screen and (max-width: 1440px) {}

@media screen and (max-width: 1024px) {
    .buck-collecton-placehlder {
        >div {
            img {
                min-width: 250px;
            }
        }
    }
    .review-collection {
        .detail-img {
            img {
                width: 250px;
            }
        }
    }
}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 768px) {
    .buck-collecton-placehlder {
        >div {
            img {
                min-width: 80%;
            }
        }
    }
    .review-collection {
        .detail-img {
            img {
                width: 100%;
            }
        }
        .text-detail {
            padding: 2rem;
            margin-top: 25px;
            .edit-field-box {
                position: relative;
                .field {
                    padding: 2.5rem !important;
                    flex-direction: column;
                    >div {
                        margin-bottom: 2rem;
                    }
                }
                .edit-btn {
                    padding-top: 2.5rem !important;
                    padding-right: 2.5rem !important;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {}

@media screen and (max-width: 561px) {
    .buck-collecton-placehlder {
        >div {
            width: 80%;
            margin-top: 3rem;
            img {
                // width: 80%;
                min-width: auto;
            }
        }
        &.bucks {
            >div {
                width: 80%;
                margin-top: 3rem;
                img {
                    // width: 80%;
                    min-width: auto;
                }
            }
        }
    }
    .buck-collecton-placehlder {
        >div {
            img {}
        }
    }
    .review-collection {
        .detail-img {
            img {}
        }
        .text-detail {
            padding: 1.5rem;
            margin-top: 25px;
            .edit-field-box {
                position: relative;
                .field {
                    padding: 2rem !important;
                    flex-direction: column;
                }
                .edit-btn {
                    padding-top: 2rem !important;
                    padding-right: 2rem !important;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
        }
    }
}