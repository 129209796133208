.creator-hub {
    .right-w-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;
        h2 {
            font-weight: normal;
            font-size: 30px;
            color: #FFFFFF;
        }
        .rs {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: nowrap;
            align-content: center;
            align-items: center;
            .tut-btn {
                display: flex;
                font-weight: normal;
                font-size: 20px;
                color: #FFFFFF;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
                align-items: center;
                justify-content: space-between;
                border: 1px solid #564ECE;
                border-radius: 36.5px;
                overflow: hidden;
                margin-right: 1rem;
                span {
                    display: block;
                    padding: 1rem 1.5rem;
                    &:nth-last-child(1) {
                        background: rgba(86, 78, 206, 0.1);
                    }
                }
            }
        }
    }
}