//MODAL
.modal-btn {
    cursor: pointer;
}

.modal-backdrop {
    &.show {
        opacity: 0.8;
    }
}

.text-w {
    color: $white-color;
}

// .detail-img {
//     position: relative;
//     svg {
//         width: 115%;
//         height: 100%;
//     }
// }
.modal {
    .modal-dialog {
        .modal-content {
            background: $dark1-color;
            box-shadow: 6px 19px 62px -8px rgb(0 0 0 / 13%);
            border-radius: 31px;
            border: 1px solid $bluelight-color;
            .modal-header {
                border-bottom: 0px solid #dee2e6;
                .modal-title {
                    width: 100%;
                    font-size: 14px;
                    color: $white-color;
                    button {
                        display: inline-block;
                    }
                    h4 {
                        margin: 0px;
                    }
                }
            }
            .modal-body {
                p {
                    &.text-w {
                        color: $white-color;
                    }
                    color: $gray3-color;
                    a {
                        color: $blue-light-color;
                    }
                }
                a {
                    color: $white-color;
                    text-decoration: none;
                }
                .address-box {
                    border-radius: 31px;
                    border: 2px solid $blue-light-color;
                }
            }
            .modal-footer {}
        }
    }
}

.tran {
    border-radius: 1rem;
    overflow: hidden;
    // border: 1px solid $blue-light-color;
    .address {
        background-color: $gray9-color;
        // border-bottom: 1px solid $blue-light-color;
        p {
            color: $white-color !important;
        }
    }
    .asset {
        background-color: $gray8-color;
        p {
            color: $white-color !important;
        }
    }
}