.stake-watchlist {
    .watchlist-section {
        h4 {
            font-weight: normal;
            font-size: 21px;
            line-height: 38px;
            color: $white-color;
        }
    }
}

.watchlist-cards {
    .watchlist-card-box {
        .holder {
            border: 1px solid transparent;
            &:hover {
                // background: linear-gradient( 65.56deg, #893af2 12.91%, #393394 50.41%);
                box-shadow: 6px 19px 62px -8px hsla(254, 54%, 46%, 0.501);
                border: 1px solid #5336b3;
                transition: 0.5s;
                .watchlist-text {
                    .art-name {
                        font-size: 18px;
                        text-align: right;
                        color: #ffffff;
                    }
                }
            }
        }
        .img-watchlist {
            img {
                width: 100%;
            }
        }
        .watchlist-text {
            position: relative;
            .category {
                font-style: italic;
                font-size: 16px;
                position: absolute;
                background: $blue2-light-color;
                color: $white-color;
                border-radius: 11px;
                top: -1rem;
            }
            .stake-name {
                font-size: 18px;
                color: $white-color;
            }
            .outof {
                min-width: 100px;
                // background-color: $blue2-light-color;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                text-align: right;
                color: rgba(255, 255, 255, 0.28);
            }
            .art-by {
                font-size: 16px;
                color: rgba(255, 255, 255, 0.28);
            }
            .art-name {
                font-size: 18px;
                text-align: right;
                color: $blue2-light-color;
            }
            .time {
                color: rgba(255, 255, 255, 0.28);
            }
            .cta {
                cursor: pointer;
                font-size: 16px;
                text-align: center;
                color: $white-color;
                border: 1px solid $blue2-light-color;
                box-sizing: border-box;
                border-radius: 22px;
                &:hover {
                    background-color: $blue2-light-color;
                    color: #ffffff;
                }
            }
            @media screen and (max-width: 768px) {
                .mob-center {
                    text-align: center;
                }
                .mob-col {
                    flex-direction: column;
                }
            }
        }
    }
}

.watchlist-cards {
    .card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        .watchlist-card-box {
            // flex: 0 1 auto;
            // flex: 1;
            // flex: auto;
            // flex-basis: auto;
            // flex: 0 1 auto;
            // width: calc($right-sidewidth / 33.33%);
            // flex-grow: 1;
            // flex-shrink: 1;
            // flex-grow: 0;
            // flex-shrink: 0;
            .holder {
                background: #04050A;
                box-shadow: 0px 4px 70px rgba(14, 13, 30, 0.86);
                border-radius: 19px;
                overflow: hidden;
                margin: 0.5rem;
                border: 1px solid transparent;
                &:hover {
                    // background: linear-gradient( 65.56deg, #893af2 12.91%, #393394 50.41%);
                    box-shadow: 6px 19px 62px -8px hsla(254, 54%, 46%, 0.501);
                    border: 1px solid #5336b3;
                    transition: 0.5s;
                }
            }
        }
    }
    @media screen and (max-width: 2560px) {
        .card-container {
            .watchlist-card-box {
                // flex: 0 1 20%;
                // width: 20%;
                width: 33.33%;
            }
        }
    }
    @media screen and (max-width: 1563px) {
        .card-container {
            .watchlist-card-box {
                // flex: 0 1 20%;
                // width: 25%;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .card-container {
            .watchlist-card-box {
                // flex: 0 1 25%;
                // width: 33.33%;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .card-container {
            .watchlist-card-box {
                // flex: 0 1 33.33%;
                // width: 33.33%;
            }
        }
    }
    @media screen and (max-width: 900px) {
        .card-container {
            .watchlist-card-box {
                // flex: 0 1 33.33%;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .card-container {
            .watchlist-card-box {
                // flex: 0 1 33.33%;
                // width: calc($right-sidewidth / 50%);
                // width: 50%;
            }
        }
    }
    @media screen and (max-width: 600px) {
        .card-container {
            .watchlist-card-box {
                flex: 0 1 50%;
            }
        }
    }
    @media screen and (max-width: 561px) {
        .card-container {
            .watchlist-card-box {
                flex: 0 1 100%;
            }
        }
    }
}