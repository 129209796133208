@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./Style/color.scss";
@import "./Style/forms.scss";
@import "./Style/react-datepicker.scss";
@import "./Style/breakpoints.scss";
@import "./Style/header.scss";
@import "~slick-carousel/slick/slick.css";
@import "node_modules/react-modal-video/scss/modal-video.scss";
@import "./Style/modal.scss";
@import "./Style/watchlist.scss";
@import "./Style/order-history.scss";
@import "./Style/star-profile.scss";
@import "./Style/similar-artist.scss";
@import "./Style/wallet-setup.scss";
@import "./Style/wallet-cart.scss";
@import "./Style/details-page.scss";
@import "./Style/cat-marketplace.scss";
@import "./Style/getbucks-marketplace.scss";
// STAKEHUB
@import "./Style/stake-hub.scss";
@import "./Style/hub-modal.scss";
@import "./Style/hub-overview-cards.scss";
@import "./Style/staked.scss";
@import "./Style/hub-watchlist.scss";
@import "./Style/charts.scss";
@import "./Style/staking-table.scss";
@import "./Style/indivisual-detail.scss";
@import "./Style/access-tokens.scss";
@import "./Style/claimed-access-tokens.scss";
@import "./Style/access-tokens-details.scss";
@import "./Style/claime-form.scss";
@import "./Style/bucks-complete-transaction.scss";
@import "./Style/home.scss";
@import "./Style/starter-bundle.scss";
//CREATOR HUB
@import "./Style/creator-hub.scss";
@import "./Style/collection-creation.scss";
@import "./Style/creation-success.scss";
@import "./Style/collection-list.scss";
@import "./Style/nftb-selector.scss";
@import "./Style/common.scss";
body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  background-color: $dark-color;
  font-size: 16px;
}

/* width */

::-webkit-scrollbar {
  width: 5px;
}

/* Track */

::-webkit-scrollbar-track {
  background: $black2-color;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #2d2f58;
  border-radius: 4px;
  overflow: hidden;
}

//--------------- container  ---------------//
.st-container {
  max-width: 1090px;
}

.z-index-1 {
  z-index: 1;
}

h1 {
  font-size: 48px;
}

//--------------- btn  ---------------//
.btn {
  padding: 20px 50px;
  box-sizing: border-box;
  border-radius: 9px;
  font-size: 20px;
  font-weight: 500;
  outline: none;
  box-shadow: none;
  &.btn-md {
    padding: 14px 40px;
    font-size: 14px;
    color: #564ece !important;
  }
  &.btn-sm {
    padding: 10px 30px;
    font-size: 10px;
    color: #564ece !important;
  }
  &.btn-outline-primary {
    border: 1.5px solid $blue-light-color;
    color: $white-color;
    &.btn-md {
      &:hover {
        color: $white-color !important;
      }
    }
    &.btn-sm {
      &:hover {
        color: $white-color !important;
      }
    }
    &:hover {
      background-color: $blue-light-color;
    }
  }
  &.btn-primary {
    background-color: $blue-light-color;
    border: 1.5px solid $blue-light-color;
    color: $white-color;
    &:hover {
      border: 1.5px solid $blue-light-color;
      background-color: rgb(91 82 255 / 0%);
    }
  }
  &.btn-light:hover {
    color: $white-color;
    background-color: rgb(4 5 10 / 37%);
    border-color: rgb(4 5 10 / 0%);
  }
}

.connectwallet-link {
  cursor: pointer;
}

//----------- email verification -----------//
.verify-email {
  padding-top: 10%;
  p {
    color: #fff;
  }
}

//--------------- navbar css ---------------//
.navbar {
  background: rgb(239 233 255 / 4%);
  .navbar-toggler {
    padding-right: 0px;
    &:focus {
      box-shadow: none;
    }
    .navbar-toggler-icon {
      background: url("./img/svg/menu-icon.svg");
      background-size: cover;
      width: 1em;
      height: 1em;
    }
  }
  .navbar-collapse {
    .navbar-nav {
      .nav-link {
        font-size: 16px;
        font-weight: 500;
        color: $white-color;
        padding-left: 60px;
        padding-right: 0;
        transition: 0.3s;
        &:hover {
          color: $blue5-light-color;
          transition: 0.3s;
        }
      }
      &.right-nav {
        .nav-link {
          padding-left: 0px;
          margin-left: 14px;
          &.nav-btn1 {
            padding: 8px 16px !important;
            background: $black4-color;
            border-radius: 100px;
            span {
              background: -webkit-linear-gradient(
                45deg,
                $greenlight1-color,
                $bluelight-color,
                $par-color
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
          &.nav-btn2 {
            background: rgb(171 171 171);
            border-radius: 25px;
            padding: 5px 15px !important;
            font-size: 13px;
          }
        }
      }
    }
  }
  &.colorChange {
    background: $black3-color;
    box-shadow: 0px 1px 8px rgb(0 0 0 / 15%);
  }
}

.mob-block {
  display: none;
}

.info-card {
  color: #fff;
  background: linear-gradient(158.07deg, #564ece 6.22%, #837bff 92.27%);
  border-radius: 11px;
  h5 {
    font-weight: normal;
    font-size: 26px;
    line-height: 38px;
  }
  p {
    font-weight: normal !important;
    font-size: 13px !important;
    line-height: 25px !important;
    color: rgba(34, 25, 25, 0.7);
  }
  .btn {
    width: 131px;
    height: 38px;
    background: #564ece;
    border-radius: 9px;
    font-weight: normal;
    font-size: 14px;
  }
}

@media screen and (max-width: 1230px) {
  .navbar .navbar-collapse .navbar-nav .nav-link {
    font-size: 12px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 991px) {
  .mob-100 {
    width: 100% !important;
  }
  .navbar {
    .navbar-collapse {
      position: absolute;
      left: 0;
      right: 0;
      background: $black3-color;
      top: 56px;
      padding: 10px 20px 30px;
      .navbar-nav {
        .nav-link {
          padding-left: 4px;
          width: 100%;
          padding-bottom: 12px;
          padding-top: 12px;
        }
        &.right-nav .nav-link {
          margin-left: 0px;
          text-align: center;
        }
      }
    }
    .mob-none {
      display: none;
    }
    .right-nav .cart-nav-item {
      display: none;
    }
    .mob-block {
      display: inline-flex;
      margin-left: auto;
      align-items: center;
      .cart-nav-item {
        margin-left: 3px;
        svg {
          width: 20px !important;
        }
        .total-cart {
          width: 14px;
          height: 14px;
          font-size: 9px;
        }
      }
      .nav-btn2 {
        background: $gray6-color;
        border-radius: 25px;
        padding: 3px 7px !important;
        font-size: 11px;
        color: $white-color;
        svg {
          width: 8px !important;
        }
      }
      .nav-link {
        padding: 0 0px 0px 10px;
        svg {
          width: 24px;
        }
      }
    }
  }
}

//--------------- banner css ---------------//
.Banner {
  z-index: 2;
}

.text-banner-A {
  max-width: 647px;
  flex-wrap: wrap;
  h1 {
    font-weight: bold;
    line-height: 63px;
  }
}

.video-box-A {
  border-radius: 27px;
  overflow: hidden;
}

.video-icon-A {
  a.nav-link {
    &:before {
      content: "";
      position: absolute;
      z-index: 0;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: block;
      width: 80px;
      height: 80px;
      background: $blue2-light-color;
      border-radius: 50%;
      animation: pulse-border 1500ms ease-out infinite;
    }
  }
  .modal-video-movie-wrap {
    box-shadow: 0px 0px 100px 2px rgb(255 255 255 / 25%);
  }
  .modal-video {
    background-color: rgb(31 31 31 / 0%);
  }
  .modal-video-close-btn:before,
  .modal-video-close-btn:after {
    width: 60%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
  .modal-video-close-btn {
    top: -15px;
    right: -18px;
    background: $gray5-color;
    border-radius: 6px;
    width: 44px;
    height: 44px;
  }
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.3);
    opacity: 0;
  }
}

@media screen and (max-width: 991px) {
  .mob-banner-a {
    flex-wrap: wrap;
    justify-content: flex-start !important;
    .mob-banner-b {
      position: unset !important;
    }
  }
  .bg-shadow-video svg {
    top: 18px;
  }
  .bg-shadow-video {
    right: 0;
  }
}

@media screen and (max-width: 600px) {
  .text-banner-A h1 {
    font-size: 35px;
    line-height: 45px;
  }
  .video-box-A svg {
    width: 100%;
    height: auto;
  }
  .bg-shadow-video svg {
    top: 18px;
    width: 100%;
  }
  .btn {
    padding: 14px 30px;
    font-size: 14px;
  }
  .card-img {
    margin-bottom: -33px !important;
  }
}

//----------- banner img shadow css ---------------//
.shape-banner-bg {
  top: 13%;
}

.bg-shadow-video {
  right: -90px;
  svg {
    position: relative;
    top: -20px;
    right: 0px;
  }
}

//----------- info section  css ---------------//
.info-section {
  border-bottom: 0.7px solid $blue2-light-color;
  .border-s {
    border-left: 0.7px solid;
    border-right: 0.7px solid;
  }
  .border-x {
    border-image: linear-gradient(to top, $blue2-light-color 0%, $black5-color)
      0 1;
  }
  .info-box {
    padding: 25px;
    h5 {
      font-size: 20px;
      color: $white2-color;
      font-weight: 500;
      margin-bottom: 16px;
    }
    p {
      color: $gray4-color;
      font-size: 16px;
      line-height: 30px;
      font-weight: 400;
    }
  }
}

//----------- hassle section  css ---------------//
.hassle-section {
  background-image: url("./img/svg/hassle-bg-A.svg");
  background-size: cover;
  background-repeat: no-repeat;
  .heading {
    h1 {
      color: $white2-color;
      font-weight: 400px;
      font-size: 43px;
    }
    p {
      color: $gray4-color;
      font-size: 18px;
      line-height: 40px;
      font-weight: 500;
    }
  }
  ul li {
    margin: 0px 30px;
    color: $blue2-light-color;
    font-size: 20px;
    font-weight: 500;
  }
  .shadow-hassle-img {
    top: -130px;
  }
}

.About-us {
  .hassle-section {
    background-image: url("./img/aboutus/about-bg-new.png");
    .hassle-img-box {
      img {
        width: 100%;
        max-width: 795px;
      }
    }
  }

  .card-section {
    background-color: #000;
    padding-top: 4rem;
    margin-bottom: 2rem;

    .card-img {
      position: relative;
      top: 80px;
    }
  }
}

.big-bg {
  background-image: url("./img/aboutus/big-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 115%;
}

@media screen and (max-width: 600px) {
  .hassle-section .heading h1 {
    font-size: 32px !important;
  }
  .hassle-img-box svg {
    width: 100%;
    height: auto;
  }
  .hassle-section ul li {
    margin: 0px 18px !important;
    font-size: 16px !important;
  }
  .process-section h2 {
    font-size: 32px !important;
  }
}

//----------- card section  css ---------------//
.card-img {
  margin-bottom: -80px;
}

//----------- control crypto section  css ---------------//
.control-crypto-section {
  background: url("./img/Control-crypto-sec-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .heading {
    h1 {
      color: $white2-color;
      font-weight: 400;
      font-size: 43px;
    }
  }
  .control-crypto-box {
    &:hover {
      .icon-im-A {
        transition: 1s;
        background: $blue6-light-color;
        svg {
          path {
            fill: $white-color;
          }
          transition: 1s;
          transform: rotate(360deg);
        }
      }
    }
    .icon-im-A {
      width: 56px;
      height: 56px;
      transition: 1s;
      background: rgba(178, 178, 178, 0.23);
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        transition: 1s;
        transform: rotate(1deg 356deg);
      }
    }
  }
  .text-control-crypto {
    h5 {
      color: $white2-color;
      font-size: 20px;
      font-weight: 500;
    }
    p {
      font-size: 16px;
      line-height: 30px;
      color: $gray4-color;
      font-weight: 400;
    }
  }
}

@media screen and (max-width: 600px) {
  .control-crypto-section .heading h1 {
    font-size: 32px;
  }
}

//----------- shape section  css ---------------//
.shape-bg-section {
  // margin-top: -135px;
  margin-top: -50px;
}

.About-us {
  .shape-bg-section {
    margin-top: -230px;
  }
}

//----------- pick stake earn section  css ---------------//
.pick-stake-earn-section {
  background: linear-gradient(
    95.54deg,
    $blue2-light-color 20.44%,
    $blue3-light-color 121.22%
  );
  h2 {
    font-size: 43px;
    font-weight: 400;
  }
  p {
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: $white-color;
  }
}

@media screen and (max-width: 600px) {
  .pick-stake-earn-section {
    h2 {
      font-size: 30px;
    }
    .d-flex {
      flex-wrap: wrap;
    }
  }
}

//----------- process section  css ---------------//
.process-section {
  .shape-right {
    top: 180px;
  }
  h2 {
    font-weight: normal;
    font-size: 43px;
    line-height: 52px;
    color: $black-color;
  }
  .process-list {
    ul {
      &::after {
        width: 1px;
        content: "";
        background-color: $black6-color;
        position: absolute;
        left: 32px;
        top: 0;
        bottom: 80px;
      }
      li {
        &:hover span {
          background: $blue5-light-color !important;
          color: $white-color;
          border-color: $blue5-light-color;
        }
        span {
          min-width: 65px;
          min-height: 65px;
          border: 2px solid $black-color;
          box-sizing: border-box;
          border-radius: 100%;
        }
        h6 {
          font-weight: 500;
          font-size: 20px;
          line-height: 56px;
          color: $black-color;
        }
        p {
          font-weight: normal;
          font-size: 16px;
          line-height: 30px;
          color: $black2-color;
        }
        a {
          color: $blue6-light-color;
          text-decoration: none;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .process-section {
    .process-list {
      ul {
        z-index: 1;
        &::after {
          display: none;
        }
        li {
          span {
            min-width: 40px !important;
            min-height: 40px !important;
          }
          h6 {
            font-size: 14px;
            line-height: 28px;
          }
          p {
            font-weight: normal;
            font-size: 14px;
            line-height: 28px;
            color: $black2-color;
          }
        }
      }
    }
  }
}

//----------- Faq section  css ---------------//
.faq-section {
  .shape-left {
    top: -100px;
  }
  h2 {
    // font-weight: normal;
    font-weight: 400;
    font-size: 43px;
    line-height: 52px;
    color: $black-color;
  }
  .accordion-button {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 80px;
    color: $gray3-color;
    // padding: 8px 0px;
    padding: 4px 0px;
    background: rgb(0 0 0 / 0%);
    &::after {
      background-image: url("./img/svg/arrow-down.svg");
      transform: rotate(-180deg);
    }
    &:hover {
      color: $black7-color;
    }
  }
  .accordion-button:not(.collapsed) {
    color: $black7-color;
    background: none;
    box-shadow: none;
    &::after {
      background-image: url("./img/svg/arrow-open.svg");
    }
  }
  .accordion-button:focus {
    box-shadow: none;
  }
  .accordion-item {
    border: none;
    //  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    border-bottom: 2px solid rgba(0, 0, 0, 0.06);
    padding-bottom: 10px;
    background: rgb(0 0 0 / 0%);
    &:last-child {
      border-bottom: none;
    }
  }
  .accordion-body {
    font-weight: 500;
    font-size: 16px;
    // line-height: 33px;
    color: $gray2-color;
    padding: 0 0 20px;
  }
  .faq-dashboard-img {
    // bottom: -68px;
    width: 400px;
    top: 25%;
    left: 58%;
  }
}

@media screen and (max-width: 991px) {
  .faq-dashboard-img {
    position: unset !important;
  }
  .faq-section {
    h2 {
      font-size: 32px;
      line-height: 46px;
    }
    .accordion-button {
      font-size: 16px;
    }
    .accordion-body {
      font-size: 14px;
    }
  }
  .mob-br {
    display: none;
  }
}

//----------- Footer section  css ---------------//
.footer {
  &::after {
    content: "";
    position: absolute;
    width: 200px;
    height: 400px;
    bottom: -150px;
    left: auto;
    right: 100px;
    margin: auto;
    background: rgba(37, 255, 176, 0.49);
    filter: blur(235px);
    opacity: 0.2;
  }
  .Browse-box {
    background: linear-gradient(
      91.45deg,
      $blue2-light-color 25.74%,
      $blue4-light-color 69.14%
    );
    border-radius: 16px;
    padding: 35px 60px;
    h2 {
      font-weight: normal;
      font-size: 43px;
      line-height: 52px;
      color: $white-color;
    }
    .btn {
      padding: 18px 58px;
    }
  }
  h6 {
    font-weight: 500;
    font-size: 18px;
    color: $gray-color;
  }
  .left-foot {
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 33px;
      color: $white-color;
    }
    .form-control {
      width: 379px;
      height: 57px;
      background: $gray9-color;
      border-radius: 28.5px;
      border: none;
      padding: 0px 30px;
      font-weight: 500;
      font-size: 16px;
      line-height: 37px;
      color: $gray5-color;
      &:focus {
        box-shadow: 0px 0px 0px 1px $blue5-light-color;
      }
    }
  }
  .right-foot {
    h3 {
      font-weight: 600;
      font-size: 18px;
      line-height: 33px;
      color: $white-color;
    }
    ul {
      li {
        list-style: none;
        margin-bottom: 6px;
        a {
          font-weight: 500;
          font-size: 16px;
          line-height: 33px;
          color: rgba(255, 255, 255, 0.63);
          text-decoration: none;
          transition: 0.3s;
          &:hover {
            color: $blue5-light-color;
            transition: 0.3s;
          }
        }
      }
    }
  }
  .follow-link {
    ul {
      li {
        list-style: none;
        padding-right: 10px;
        a {
          width: 42px;
          height: 43px;
          background: #564ece;
          border-radius: 8px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .bottom-footer {
    ul {
      li {
        list-style: none;
        padding-left: 20px;
        a {
          font-weight: 500;
          font-size: 18px;
          color: rgba(255, 255, 255, 0.63);
          text-decoration: none;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .footer {
    .Browse-box {
      flex-wrap: wrap;
      border-radius: 12px;
      padding: 20px 20px;
      h2 {
        font-size: 30px;
        line-height: 40px;
      }
      .btn {
        padding: 12px 44px;
      }
    }
    h6 {
      font-size: 11px;
      line-height: 30px;
    }
    .left-foot {
      .form-control {
        width: 100%;
        height: 46px;
      }
    }
    .right-foot ul li {
      display: inline-block;
      width: 50%;
    }
    .bottom-footer {
      ul {
        flex-wrap: wrap;
        li {
          padding-left: 0px;
          a {
            font-size: 11px;
          }
        }
      }
    }
  }
}

//-----------Dashboard  css ---------------//
//-----------Mian wrapper  css ---------------//
//----------- side nav  css ---------------//
.side-bar-nav {
  z-index: 999999;
  .mob-btn-side-nav {
    position: absolute;
    top: -48px;
    left: 196px;
    padding: 0px 4px;
    z-index: 999999999;
    display: none;
    border-radius: 38px;
    svg {
      width: 20px;
      height: auto;
    }
  }
}

.side-nav {
  background: rgb(124 124 124 / 4%);
  width: 269px;
  height: calc(100vh - 64px);
  margin-top: 56px;
  padding: 66px 25px 30px;
  position: fixed;
  left: 0;
  top: 0;
  overflow-y: auto;
  p {
    font-weight: 500;
    font-size: 14px;
    color: $white-color;
    padding: 0 12px;
    margin-bottom: 10px;
  }
  ul {
    li {
      list-style: none;
      a {
        font-weight: 500;
        font-size: 16px;
        line-height: 37px;
        color: $gray6-color;
        text-decoration: none;
        padding: 8px 12px;
        display: block;
        transition: 0.2s;
        span {
          width: 32px;
          display: inline-block;
          text-align: center;
          margin-right: 8px;
        }
        &.active {
          background: rgba(86, 78, 206, 0.15);
          border-radius: 4px;
          color: $blue7-light-color;
          span {
            svg {
              path {
                fill: $blue7-light-color;
              }
            }
          }
        }
        &:hover {
          color: $blue5-light-color;
          span {
            svg {
              path {
                fill: $blue7-light-color;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .side-bar-nav {
    .mob-btn-side-nav {
      display: block;
    }
    .side-nav {
      background: rgb(9 10 15);
      transition: 0.5s;
      left: -300px;
      &.open-nav {
        left: 0px;
        transition: 0.5s;
      }
    }
  }
}

//-----------right  wrapper  css ---------------//
.right-wrapper {
  height: calc(100vh - 64px);
  margin-top: 64px;
  padding-left: 269px;
  padding-right: 25px;
  overflow-y: auto;
  height: 100%;
}

.right-scroll-wrapper {
}

@media screen and (max-width: 1100px) {
  .right-wrapper {
    padding-left: 0;
    padding-right: 0px;
  }
}

//-----------Starstake setup  css ---------------//
.new_user_Account {
  .nav-pills {
    &:before {
      content: "";
      position: absolute;
      left: 31px;
      border-left: 1px dashed $white-color;
      top: 12px;
      bottom: 10px;
    }
    .nav-link {
      color: $white-color;
      background: none;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      align-items: center;
      display: flex;
      cursor: pointer;
      span {
        min-width: 31px;
        height: 31px;
        background: rgb(69 69 73);
        display: inline-block;
        border-radius: 100%;
        z-index: 1;
      }
      &.active {
        span {
          background-color: $blue2-light-color;
          transform: scale(1.2);
        }
      }
      &.active-done {
        span {
          background-color: $blue2-light-color;
          transform: scale(1.2);
          &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: -3px;
            bottom: 0;
            transform: rotate(45deg);
            height: 18px;
            width: 9px;
            border-bottom: 3px solid $white-color;
            border-right: 3px solid $white-color;
            margin: auto;
          }
        }
      }
    }
  }
  .connect-user-new {
    h2,
    h3 {
      color: $white-color;
      font-weight: normal;
      font-size: 30px;
      line-height: 38px;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $gray4-color;
      a {
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
  .setup-bg-box {
    width: 628px;
    height: 533px;
    background: $dark1-color;
    // box-shadow: 6px 19px 62px -8px rgb(0 0 0 / 13%);
    border-radius: 31px;
    .btn {
      width: 316px;
      padding-left: 0;
      padding-right: 0;
    }
    a {
      font-weight: 500;
      font-size: 20px;
      text-align: center;
      color: $blue2-light-color;
      text-decoration: none;
    }
    .form-control {
      height: 63px;
      background: rgba(196, 196, 196, 0);
      border-radius: 31.5px;
      border: none;
      padding: 0px 30px;
      font-weight: 500;
      font-size: 16px;
      line-height: 37px;
      color: $gray5-color;
      box-shadow: 0px 0px 0px 1px $gray9-color;
      &:focus {
        box-shadow: 0px 0px 0px 1px $blue5-light-color;
      }
    }
  }
}

.height-heading {
  height: 36px;
}

@media screen and (max-width: 1100px) {
  .new_user_Account {
    .setup-bg-box {
      width: 100%;
      height: auto;
    }
  }
}

@media screen and (max-width: 991px) {
  .height-heading {
    height: auto;
    h2 {
      position: unset !important;
      font-size: 20px !important;
    }
  }
  .connect-user-new {
    h3 {
      font-size: 20px !important;
    }
    p {
      font-size: 13px !important;
    }
  }
  .video-boxs-Tutorial {
    svg {
      width: 100%;
      height: auto;
    }
  }
  .new_user_Account .setup-bg-box a {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 600px) {
  .new_user_Account .setup-bg-box .btn {
    width: 100% !important;
  }
}

//-----------My  collection  css ---------------//
//-----------banner  collection  css ---------------//
.banner-collection {
  background: url("./img/svg/Banner.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .total-blance {
    border-right: 2px solid;
    margin-bottom: -20px;
    h4 {
      font-weight: 600;
      font-size: 36px;
      letter-spacing: -0.03em;
      color: $color-B2FFD6;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      text-align: center;
      letter-spacing: -0.03em;
      color: $gray4-color;
    }
    span {
      font-weight: 500;
      font-size: 16.15px;
      width: 97.58px;
      height: 39.66px;
      background: rgba(140, 249, 190, 0.12);
      border-radius: 7.07603px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: rgba(255, 255, 255, 0.71);
    }
  }
  .border-right {
    border-image: linear-gradient(to bottom, $white-color 0%, $black5-color) 0 1;
  }
  .action-text {
    h4 {
      font-weight: normal;
      font-size: 30px;
      line-height: 38px;
    }
    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: rgba(255, 255, 255, 0.88);
    }
    .btn {
      padding: 10px 40px;
      font-weight: normal;
      font-size: 16px;
      color: $blue2-light-color;
      &:hover {
        color: $white-color;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .banner-collection {
    .total-blance {
      svg {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .banner-collection {
    border-radius: 32px;
    .total-blance {
      border: none;
    }
  }
}

//-----------Your  collection css ---------------//
.your-collection {
  h3 {
    font-weight: normal;
    font-size: 21px;
    line-height: 38px;
    color: $white-color;
  }
}

//-----------filter  collection css ---------------//
.filter-section {
  .search-box {
    max-width: 318px;
    width: 100%;
    .form-control {
      height: 52px;
      background: $gray9-color;
      border-radius: 26px;
      border: none;
      padding: 0px 10px 0px 50px;
      color: $white-color;
      &:focus {
        box-shadow: 0px 0px 0px 1px $blue8-light-color;
      }
      &::placeholder {
        color: $gray11-color;
      }
    }
    span {
      padding: 14px 20px;
    }
  }
  .collection-box {
    max-width: 250px;
    width: 100%;
  }
  .denomination-box {
    max-width: 250px;
    width: 100%;
  }
  .box-dropdown {
    width: 100%;
    .form-select {
      height: 52px;
      background-color: $gray9-color;
      border-radius: 26px;
      border: none;
      color: $gray11-color;
      padding: 0px 24px;
      background-position: right 1rem center;
      background-size: 22px 16px;
      &:focus {
        box-shadow: 0px 0px 0px 1px $blue8-light-color;
      }
      option {
        background: #18181d;
        color: $white-color;
        font-size: 14px;
      }
    }
  }
  .filter-box {
    max-width: 270px;
    width: 100%;
    button {
      border: 1px solid $gray7-color;
      box-sizing: border-box;
      border-radius: 26px;
      background: none;
      color: $gray11-color;
      padding: 0px 30px;
      height: 52px;
      &:focus {
        box-shadow: 0px 0px 0px 1px $blue8-light-color;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .filter-section {
    .search-box {
      max-width: 48%;
      margin-bottom: 10px;
    }
    .collection-box {
      max-width: 48%;
      margin-bottom: 10px;
    }
    .denomination-box {
      max-width: 48%;
    }
    .filter-box {
      max-width: 48%;
      width: 100%;
    }
  }
}

@media screen and (max-width: 400px) {
  .filter-section {
    .search-box {
      max-width: 100%;
      margin-bottom: 10px;
    }
    .collection-box {
      max-width: 100%;
      margin-bottom: 10px;
    }
    .denomination-box {
      max-width: 100%;
      margin-bottom: 10px;
    }
    .filter-box {
      max-width: 100%;
      width: 100%;
    }
  }
}

//-----------list  collection css ---------------//
.collection-list {
  .collection-list-box {
    width: 200px;
    .img-hover-click {
      background: linear-gradient(
        162.24deg,
        rgba(157, 153, 208, 0.82) 3.31%,
        rgba(64, 51, 255, 0.82) 97.08%
      );
      opacity: 0;
      transition: 0.2s;
      a {
        text-decoration: none;
        p {
          font-weight: 500;
          font-size: 16px;
          color: $white-color;
        }
      }
    }
    .img-collection {
      svg {
        width: 100%;
      }
      &:hover {
        .img-hover-click {
          opacity: 1;
        }
      }
    }
    .collection-text {
      .name-br {
        font-weight: 500;
        font-size: 16px;
        color: #8781e0;
      }
      h5 {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $white-color;
      }
      .price {
        width: 69px;
        height: 34px;
        background: $gray9-color;
        border-radius: 26px;
        font-weight: 500;
        font-size: 16px;
        color: $blue8-light-color;
      }
      .qty {
        font-weight: 500;
        font-size: 16px;
        color: $gray10-color;
      }
      hr {
        background: $gray8-color;
      }
      h6 {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $white-color;
      }
    }
  }
}

@media screen and (max-width: 1260px) {
  .collection-list {
    .collection-list-box {
      width: 194px;
      .img-collection {
        svg {
          width: 100%;
          height: auto;
        }
      }
      .collection-text h5 {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .mob-wrap {
    flex-wrap: wrap;
  }
  .collection-list {
    .collection-list-box {
      width: 48%;
    }
  }
}

@media screen and (max-width: 400px) {
  .collection-list .collection-list-box .collection-text h5 {
    font-size: 12px;
  }
  .navbar-brand svg {
    width: 130px;
  }
  .side-bar-nav .mob-btn-side-nav {
    left: 168px;
  }
}

//-----------account sectting css ---------------//
.setting-section {
  h4 {
    font-weight: normal;
    font-size: 21px;
    line-height: 38px;
    color: $white-color;
  }
  .account-sectting {
    background: $dark1-color;
    box-shadow: 6px 19px 62px -8px rgb(0 0 0 / 13%);
    border-radius: 31px;
    .form-label {
      font-weight: 500;
      font-size: 16px;
      color: $white-color;
    }
    .form-control {
      height: 73px;
      border: 2px solid $blue2-light-color;
      box-sizing: border-box;
      border-radius: 36.5px;
      background: rgb(0 0 0 / 0%);
      color: rgb(255 255 255 / 27%);
      padding: 0px 90px 0px 32px;
    }
  }
  .btn.btn-secondary {
    background: #282640;
    border-color: #282640;
  }
}

@media screen and (max-width: 1500px) {
  .setting-section {
    .account-sectting {
      .w-75 {
        width: 85% !important;
        .btn {
          padding: 20px 26px !important;
          font-size: 17px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .setting-section {
    .account-sectting {
      .w-75 {
        width: 100% !important;
        .btn {
          padding: 20px 26px !important;
          font-size: 17px !important;
        }
      }
    }
  }
}

//-----------profile  css ---------------//
.profile-wallet-address {
  h6 {
    span {
      background: -webkit-linear-gradient(45deg, #85ffc4, #5cc6ff, #bc85ff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.profile-text{
  padding-top: 40px;
}

.upload-img {
  input {
    opacity: 0;
    // width: 229px;
    // height: 53px;
    left: 0;
    top: 0;
    width: 122px;
     height: 122px;
  }
  .btn {
    color: $blue7-light-color;
    width: 229px;
    height: 53px;
  }
}

.remove-img {
  a {
    color: rgba(171, 171, 171, 1);
    text-decoration: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 44px;
  }
}

.profile-text {
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.66);
  }
  .form-control {
    width: 492px;
    height: 65px;
    border: 2px solid $blue2-light-color;
    box-sizing: border-box;
    border-radius: 36.5px;
    background: none;
    padding: 0px 40px;
    font-weight: 500;
    font-size: 16px;
    &:focus {
      box-shadow: none;
    }
  }
  .btn {
    background: none;
    border: none;
    font-weight: 500;
    font-size: 18px;
    color: $blue2-light-color;
    &:focus,
    &:hover {
      border: none;
      box-shadow: none;
    }
  }
}

.noti-text {
  width: 335px;
  h5 {
    font-weight: 500;
    font-size: 18px;
    color: $white-color;
  }
}

.custom-control {
  position: relative;
  width: 67px;
  height: 32px;
  .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
    &:checked ~ .custom-control-label::before {
      color: $white-color;
      background-color: $blue2-light-color;
    }
  }
  .custom-control-label {
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 67px;
      height: 32px;
      content: "";
      background-color: rgba(86, 78, 206, 0.2);
      border-radius: 40px;
    }
    &::after {
      position: absolute;
      top: 3px;
      left: 4px;
      width: 26px;
      height: 26px;
      content: "";
      background: no-repeat 50% / 50% 50%;
      background-color: $blue2-light-color;
      border-radius: 100%;
      transition: transform 0.15s ease-in-out,
        background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    }
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: $white-color;
  transform: translateX(2.1rem);
}

@media screen and (max-width: 800px) {
  .profile-section {
    .text-end {
      text-align: left !important;
    }
  }
  .profile-text .form-control {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .profile-text p {
    font-size: 12px;
  }
  .img-profile {
    width: 100px;
    svg {
      width: 100%;
    }
  }
  .remove-img a {
    font-size: 14px;
    line-height: 36px;
  }
  .upload-img {
    input {
      width: 190px;
      height: 48px;
    }
    .btn {
      width: 190px;
      height: 48px;
    }
  }
  .noti-text h5 {
    font-size: 14px;
  }
}

//-----------Shipping Address  css ---------------//
.shipping-section {
  h4 {
    font-weight: normal;
    font-size: 21px;
    line-height: 38px;
    color: $white-color;
  }
  .box-address {
    background: $black8-color;
    border-radius: 11px;
    h4 {
      font-weight: normal;
      font-size: 21px;
      color: $white-color;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      color: $gray12-color;
    }
    h3 {
      font-weight: normal;
      font-size: 21px;
      line-height: 30px;
      color: $white-color;
    }
    .btn {
      width: 229px;
      height: 53px;
      &:focus,
      &:hover {
        box-shadow: none;
      }
      &.btn-outline-primary {
        color: $blue2-light-color;
        &:hover {
          color: $white-color;
        }
      }
      &.btn-none {
        background: none;
        border: none;
        &:focus,
        &:hover {
          border: none;
        }
      }
    }
  }
  .add-address {
    .btn {
      width: 316px;
      height: 73px;
    }
  }
}

.add-address-modal {
  .modal-dialog {
    max-width: 628px;
    margin-top: 77px;
  }
  .modal-content {
    background-color: $dark1-color;
    color: $white-color;
    border-radius: 40px;
    .modal-header {
      border-bottom: none;
      span {
        cursor: pointer;
        padding-left: 40px;
      }
    }
    .form-label {
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
    }
    .form-control {
      width: 100%;
      height: 65px;
      border: 2px solid $blue2-light-color;
      box-sizing: border-box;
      border-radius: 36.5px;
      background: none;
      padding: 0px 40px;
      font-weight: 500;
      font-size: 16px;
    }
    .btn {
      width: 316px;
      height: 73px;
    }
  }
}

@media screen and (max-width: 800px) {
  .shipping-section {
    .box-address {
      h3 {
        font-size: 14px;
      }
      h4 {
        font-size: 15px;
      }
      p {
        font-size: 13px;
      }
      .btn {
        width: 130px;
        height: 40px;
      }
    }
    .add-address .btn {
      width: 254px;
      height: 59px;
    }
  }
  .add-address-modal .modal-content {
    .form-control {
      height: 50px;
      padding: 0px 15px;
      font-size: 12px;
    }
    .form-label {
      font-size: 12px;
    }
    .modal-header span {
      cursor: pointer;
      padding-left: 20px;
    }
    .btn {
      width: 100%;
      height: 50px;
    }
  }
}

//-----------NFTbucks  css ---------------//
.Essentials-banner {
  background: linear-gradient(106.96deg, #6ef5c4 -27.85%, #0a12ca 110.71%);
  // border-radius: 40px;
  min-height: 450px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  .bg {
    // height: 100%;
    top: 0px;
    right: 0px;
    filter: opacity(0.5);
  }
  h3 {
    font-weight: 600 !important;
    font-size: 54px !important;
    color: #ffffff;
  }
  p.text {
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
  }
  .btn {
    color: $blue5-light-color;
    font-weight: normal;
    font-size: 16px;
  }
  .text-content {
    width: 60% !important;
  }
  @media screen and (max-width: 561px) {
    max-height: 100%;
    .text-content {
      width: 100% !important;
    }
    .bg {
      display: none;
    }
  }
}

.Essentials-list {
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    color: #ffffff;
    span {
      svg {
        path {
          fill: #564ece;
        }
      }
    }
  }
  .collection-list-box {
    width: 200px;
    margin-left: 15px;
    margin-right: 15px;
    .img-collection {
      overflow: hidden;
      border-radius: 10px;
      svg {
        transform: scale(1);
        transition: 0.5s;
      }
      &:hover {
        svg {
          transform: scale(1.1);
          transition: 0.5s;
        }
      }
      .img-hover-click {
        background: linear-gradient(
          162.24deg,
          rgba(157, 153, 208, 0.82) 3.31%,
          rgba(64, 51, 255, 0.82) 97.08%
        );
        opacity: 0;
        transition: 0.5s;
        border-radius: 10px;
        a {
          text-decoration: none;
          p {
            font-weight: 500;
            font-size: 14px;
            color: $white-color;
          }
        }
        svg {
          width: 53px;
        }
      }
      &:hover {
        .img-hover-click {
          opacity: 1;
          transition: 0.5s;
          svg {
            transform: scale(1);
          }
        }
      }
    }
  }
  // margin-top: -150px;
  .price.d-flex.align-items-center.justify-content-center {
    font-weight: 500;
    font-size: 16px;
    color: $blue2-light-color;
  }
  .add-cart-btn {
    width: 85px;
    height: 25px;
    background: rgba(196, 196, 196, 0.1);
    border-radius: 18px;
    font-weight: 500;
    font-size: 11px;
    color: rgba(255, 255, 255, 0.5);
    border: none;
  }
}

@media screen and (max-width: 1800px) {
  .lxx-screen {
    display: none;
  }
}

@media screen and (max-width: 1600px) {
  .lg-screen {
    display: none;
  }
}

@media screen and (max-width: 921px) {
  .lg-screen {
    display: block;
  }
  .lxx-screen {
    display: block;
  }
}

.collections-list {
  h3 {
    font-weight: normal;
    font-size: 21px;
    color: $white-color;
  }
  .slick-slider {
    .slick-track {
      margin-left: 0;
      float: left;
    }
  }
  .nav-pills {
    .nav-item {
      .nav-link {
        font-weight: 500;
        font-size: 16px;
        color: $gray6-color;
        cursor: pointer;
        &.active {
          background: rgba(196, 196, 196, 0.1);
          border-radius: 26px;
          color: $blue8-light-color;
        }
      }
    }
  }
}

.bundles-list {
  .slick-slider {
    .slick-track {
      float: left;
    }
  }
  h3 {
    font-weight: normal;
    font-size: 21px;
    color: $white-color;
  }
  .view-bundle-btn {
    background: rgba(196, 196, 196, 0.1);
    border-radius: 21px;
    font-weight: 500;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.5);
    border: none;
    padding: 10px 20px;
    text-decoration: none;
    &:hover {
      border: none;
      background: $blue-light-color;
      color: $white-color;
    }
  }
  .collection-list-box {
    width: 214px;
    margin-left: 15px;
    margin-right: 15px;
    h6 {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $white-color;
    }
    .price {
      background: rgba(196, 196, 196, 0.1);
      border-radius: 21px;
      font-weight: 500;
      font-size: 15px;
      color: $blue8-light-color;
      border: none;
      padding: 5px 15px;
    }
  }
  .bundles-hover {
    // background: linear-gradient(180deg, $blue2-light-color 0%, rgba(86, 78, 206, 0) 100%);
    // border-radius: 14px;
    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 110%;
      /* or 22px */
      color: #ffffff;
    }
    hr {
      margin: 0.5rem 0 0rem 0;
    }
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 110%;
      /* identical to box height, or 11px */
      color: #d6d6d6;
    }
  }
  @media screen and (max-width: 561px) {
    .collection-list-box {
      img {
        width: 100%;
      }
    }
  }
}

//-----------slick-slider  css ---------------//
.slick-slider {
  width: 90%;
  .collections-box {
    width: 213px;
    .collections-img {
      display: flex;
      .main-img {
        border-radius: 8px;
      }
    }
    .view-Collection-btn {
      background: rgba(196, 196, 196, 0.1);
      border-radius: 21px;
      font-weight: 500;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.5);
      border: none;
      padding: 10px 20px;
      text-decoration: none;
      &:hover {
        border: none;
        background: $blue-light-color;
        color: $white-color;
      }
    }
    .total-list {
      font-weight: 500;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.5);
      display: flex;
      align-items: center;
    }
    .collections-hover {
      background: linear-gradient(
        180deg,
        $blue2-light-color 0%,
        rgba(86, 78, 206, 0) 100%
      );
    }
  }
  .slick-prev {
    // display: none !important;
    position: absolute;
    left: -80px;
    top: 0;
    bottom: 0;
    background: none;
    border: none;
    font-size: 0;
    padding: 0;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      background-color: $white-color;
      width: 60px;
      height: 60px;
      color: $blue2-light-color;
      font-size: 53px;
      margin: auto;
      bottom: 0;
      border-radius: 100%;
      background-image: url('./img/leftArrowLgt.png');
      background-repeat: no-repeat;
      background-position: center;
    }
    &:hover::after {
      background-color: rgb(72 79 195 / 28%);
      color: $white-color;
    }
  }
  .slick-next {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: none;
    border: none;
    font-size: 0;
    padding: 0;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      background-color: $white-color;
      width: 60px;
      height: 60px;
      color: $blue2-light-color;
      font-size: 53px;
      margin: auto;
      bottom: 0;
      border-radius: 100%;
      background-image: url("./img/svg/right-arrow.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
    &:hover::after {
      background-color: rgb(72 79 195 / 28%);
      color: $white-color;
    }
  }
  .slick-dots {
    position: absolute;
    right: 80px;
    top: -70px;
    display: flex !important;
    list-style: none;
    padding: 0;
    li {
      width: 5px;
      margin: 0px 3px;
      height: 5px;
      background: $gray6-color;
      border-radius: 100%;
      &.slick-active {
        background: $blue2-light-color;
      }
      button {
        opacity: 0;
        display: none;
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .Essentials-list {
    // margin-top: -90px;
  }
  .collections-list {
    .slick-slider {
      width: 96%;
      .collections-box {
        width: 190px;
        img {
          width: 121%;
        }
      }
    }
  }
  .bundles-list {
    .collection-list-box {
      width: 186px;
      .img-svg {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .Essentials-banner {
    .w-50 {
      width: 100% !important;
    }
  }
  .Essentials-list {
    margin-top: 30px;
    .collection-list-box {
      padding: 0px 15px;
    }
    .mob-wrap {
      justify-content: space-around !important;
    }
  }
  .collections-list {
    .slick-slider {
      width: 91% !important;
    }
  }
}

@media screen and (max-width: 700px) {
  .Essentials-banner {
    .position-absolute {
      position: unset !important;
      background: #000;
    }
  }
  .collections-list .nav-pills .nav-item .nav-link {
    font-size: 12px;
  }
}

@media screen and (max-width: 500px) {
  .Essentials-list .collection-list-box {
    padding: 0px 4px !important;
    width: 158px;
  }
  .collections-list .slick-slider {
    width: 100% !important;
    .slick-next {
      display: none !important;
    }
    ul.slick-dots {
      display: none !important;
    }
    .collections-box {
      width: 200px;
      margin: auto;
    }
  }
}

//-----------NFTbucks  css ---------------//
.single-detail-bundle {
  .detail-img {
    margin-right: -50px;
    z-index: 1;
    img {
      width: 350px;
    }
  }
  .text-detail {
    background: #0b0d19;
    border-radius: 24px;
    padding: 40px 50px 40px 140px;
    h6 {
      font-weight: 500;
      font-size: 16px;
      color: $gray6-color;
    }
    ul li {
      list-style: none;
    }
    h5 {
      font-weight: normal;
      font-size: 21px;
      color: $gray6-color;
    }
    .price-detail {
      font-weight: normal;
      font-size: 21px;
      color: $blue2-light-color;
    }
    .auth-detail {
      font-weight: 500;
      font-size: 16px;
      color: $gray6-color;
    }
    h1 {
      font-weight: normal;
      font-size: 32px;
      color: $white-color;
    }
    .view-bundle-btn {
      background: rgba(196, 196, 196, 0.1);
      border-radius: 45px;
      font-weight: 500;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.5);
      border: none;
      padding: 13px 30px;
      text-decoration: none;
      &:hover {
        border: none;
        background: $blue-light-color;
        color: $white-color;
      }
    }
    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 38px;
      color: $gray6-color;
    }
  }
  .standard-bucks {
    background: #0b0d19;
    border-radius: 24px;
    h2 {
      font-weight: normal;
      font-size: 21px;
      color: $white-color;
      span {
        color: $blue2-light-color;
      }
    }
    .slick-slider {
      .slick-track {
        margin-left: 0;
        float: left;
      }
      .slick-dots {
        right: -40px;
        top: -60px;
      }
      .collections-box {
        width: 192.91px;
        overflow: hidden;
        .img-hover-click {
          background: linear-gradient(
            162.24deg,
            rgba(157, 153, 208, 0.82) 3.31%,
            rgba(64, 51, 255, 0.82) 97.08%
          );
          opacity: 0;
          transition: 0.2s;
          a {
            text-decoration: none;
            p {
              font-weight: 500;
              font-size: 16px;
              color: $white-color;
            }
          }
        }
        &:hover {
          .img-hover-click {
            opacity: 1;
          }
        }
      }
    }
  }
}

//-----------Founders Collection  css ---------------//
.headings {
  h1 {
    font-weight: normal;
    font-size: 30px;
  }
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.58);
  }
}

.main h3 {
  font-weight: normal;
  font-size: 21px;
}

.founderscollectionlist {
  margin: 0 -15px;
  .name-br.mb-3 {
    font-weight: 500;
    font-size: 16px;
    color: $blue8-light-color;
  }
  .add-cart-btn {
    width: 122px;
    height: 36px;
    background: rgba(196, 196, 196, 0.1);
    border-radius: 18px;
    font-weight: 500;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.5);
    border: none;
  }
  .price {
    background: none !important;
    width: auto !important;
  }
  &.collection-list .collection-list-box {
    width: 200px;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.token-info {
  background: #0b0d19;
  border-radius: 24px;
  h5 {
    font-weight: normal;
    font-size: 21px;
    color: $gray6-color;
  }
  div {
    font-weight: 500;
    font-size: 16px;
    .gray-col {
      color: $gray6-color;
    }
    .secondary-col {
      color: $blue2-light-color;
    }
  }
}

@media screen and (max-width: 500px) {
  .founderscollectionlist.collection-list .collection-list-box {
    width: 158px;
  }
  .single-detail-bundle {
    ul.slick-dots {
      display: none !important;
    }
    .slick-slider {
      width: 100%;
      .slick-next {
        display: none !important;
      }
    }
    .standard-bucks h2 {
      font-size: 15px;
    }
  }
}

//-----------BuckDetail  css ---------------//
.secondary-col {
  color: $blue2-light-color;
}

.text-action-img {
  p {
    font-size: 16px;
    color: #343342;
  }
  .add-cart-btn {
    width: 122px;
    height: 36px;
    background: rgba(196, 196, 196, 0.1);
    border-radius: 35px;
    font-weight: 500;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.5);
    border: none;
  }
  .custom-control {
    margin: 0 auto;
  }
}

@media screen and (max-width: 991px) {
  .single-detail-bundle .text-detail {
    padding: 30px 20px 30px 20px;
  }
}

//-----------Cart  css ---------------//
.cart-nav-item {
  background: none !important;
  border: none !important;
  &:focus {
    box-shadow: none;
    outline: none;
  }
  .total-cart {
    width: 18px;
    height: 18px;
    background: #fff;
    right: -7px;
    border-radius: 100%;
    font-weight: bold;
    font-size: 14px;
    color: #564ece;
  }
  &:hover {
    svg {
      path {
        fill: #564ece;
      }
    }
    .hover-open-dropdown {
      opacity: 1;
      width: 349px;
      transition: 0.5s;
      height: 48px;
      a {
        font-size: 16px;
        transition: 0.5s;
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
  .hover-open-dropdown {
    right: -10px;
    background: #564ece;
    box-shadow: 0px 4px 45px -1px rgb(86 78 206 / 36%);
    border-radius: 9px;
    width: 0;
    height: 0;
    top: 62px;
    opacity: 0;
    transition: 0.5s;
    a {
      color: #fff;
      text-decoration: none;
      font-size: 0;
      transition: 0.5s;
    }
    &::after {
      content: "";
      border-left: 12px solid #fff0;
      border-top: 20px solid #fff0;
      border-bottom: 20px solid #585fcf;
      border-right: 12px solid #fff0;
      position: absolute;
      right: 13px;
      top: -39px;
    }
  }
}

.cart-slide-right {
  right: 0px;
  transition: 0.8s;
  width: 100%;
  background: rgba(0, 0, 0, 0.71);
  position: fixed;
  top: 62px;
  bottom: 0;
  z-index: 999999999999;
  .bg-cart-close {
    background: none !important;
    border: none !important;
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
  .cart-bg {
    width: 542px;
    height: 100vh;
    background: #0b0d19;
    box-shadow: 6px 19px 62px -8px rgb(0 0 0 / 13%);
    margin-right: 0;
    margin-left: auto;
    overflow-y: auto;
    .cart-heading {
      h3 {
        font-weight: normal;
        font-size: 21px;
      }
    }
    .balance-eth {
      font-weight: 500;
      font-size: 18px;
      span {
        font-weight: 500;
        font-size: 16px;
        color: #564ece;
      }
    }
    .text-end.need-text {
      font-weight: 500;
      font-size: 18px;
      span {
        color: #564ece;
      }
    }
    .main-heading {
      h1 {
        font-weight: 500;
        font-size: 25px;
      }
    }
    .liat-cart-slide {
      height: calc(100vh - 480px);
      overflow: auto;
      .text-cart-list {
        h6 {
          font-weight: normal;
          font-size: 19px;
          line-height: 32px;
        }
        .cart-price {
          font-weight: normal;
          font-size: 21px;
          color: #564ece;
        }
        .quantity {
          font-weight: normal;
          font-size: 19px;
          .form-select {
            width: 81px;
            height: 34px;
            background-color: rgba(229, 229, 229, 0.13);
            border-radius: 22px;
            color: #fff;
            border: none;
            background-position: right 1rem center;
            background-size: 14px 17px;
            padding: 0px 25px;
            &:focus {
              box-shadow: 0px 0px 0px 1px $blue8-light-color;
            }
            option {
              background: #18181d;
              color: $white-color;
              font-size: 14px;
            }
          }
        }
        .eth-text {
          font-weight: normal;
          font-size: 21px;
          color: rgba(255, 255, 255, 0.23);
        }
      }
    }
    .summary {
      background: rgba(86, 78, 206, 0.06);
      h5 {
        font-weight: 500;
        font-size: 16px;
        color: #646464;
      }
      .sumary-text {
        font-weight: 500;
        font-size: 16px;
      }
    }
    .checkout-action {
      .btn {
        width: 100%;
        border-radius: 0;
      }
    }
  }
  &.cart-none {
    right: -400px;
    transition: 0.8s;
    width: 0;
  }
}

@media screen and (max-width: 991px) {
  .mob-none {
    display: none;
  }
  .cart-nav-item {
    &:hover {
      .hover-open-dropdown {
        width: 248px;
        height: 44px;
        a {
          font-size: 11px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .cart-slide-right {
    .cart-bg {
      width: 100%;
      .cart-heading h3 {
        font-weight: normal;
        font-size: 14px;
      }
      .balance-eth {
        font-size: 10px;
      }
      .text-end.need-text {
        font-size: 14px;
      }
      .main-heading h1 {
        font-size: 20px;
      }
      .liat-cart-slide {
        height: calc(100vh - 433px);
        .text-cart-list {
          h6 {
            font-size: 14px;
          }
          .quantity {
            font-size: 12px;
            .form-select {
              width: 72px;
              padding: 0px 16px;
            }
          }
          .cart-price {
            font-size: 16px;
          }
          .eth-text {
            font-size: 16px;
          }
        }
      }
      .summary .sumary-text {
        font-size: 13px;
      }
    }
  }
}

//-----------Congratulations your  Bucks css ---------------//
.tab-active-top {
  ul {
    li {
      list-style: none;
      width: 142px;
      &::after {
        content: "";
        position: absolute;
        background-image: linear-gradient(
          to right,
          rgba(255, 255, 255, 1),
          rgba(255, 255, 255, 0)
        );
        height: 3px;
        width: 108%;
        right: 0;
        left: 64px;
        top: 8px;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
      span {
        width: 20px;
        height: 20px;
        background: #ffffff;
        display: inline-block;
        border-radius: 100%;
        border: 4px solid rgba(172, 170, 170, 0.56);
        position: relative;
        z-index: 1;
      }
      h4 {
        font-weight: 500;
        font-size: 16px;
        color: #ffffff;
      }
    }
  }
}

.Bucks-To-Stake {
  position: relative;
  &::after {
    content: "";
    background-image: url("./img/bg-shape-bucks.png");
    position: absolute;
    left: -25px;
    top: 27%;
    width: 210px;
    height: 336px;
  }
  .setup-bg-box {
    height: 631px !important;
  }
}

@media screen and (max-width: 600px) {
  .Bucks-To-Stake {
    .setup-bg-box {
      height: auto !important;
    }
  }
}

//-----------cart Model css ---------------//
.model-cart-item {
  position: fixed;
  right: 100px;
  bottom: 100px;
  width: 306px;
  box-shadow: 0px 0px 56px 46px #0b0d1996;
  background: rgb(10 13 24 / 58%);
  .close-cart-item {
    margin-bottom: 10px;
    svg {
      width: 28px;
      height: 28px;
      cursor: pointer;
      path {
        fill-opacity: 1;
      }
    }
  }
  h6 {
    font-size: 26px;
    margin-bottom: 16px;
    line-height: 44px;
  }
  .btn {
    width: 100%;
    svg path {
      fill: #fff;
    }
  }
}

//-----------Inft video tem css ---------------//
.video-banner {
  background: url("./img/video-banner.png") no-repeat;
  background-size: cover;
  background-position: center top;
  width: 100%;
  height: auto;
  margin-top: 56px;
}

.video-text-left {
  min-width: 561px;
  height: 522px;
  background: rgba(0, 0, 0, 0.42);
  backdrop-filter: blur(53px);
  border-radius: 12px;
  text-align: center;
  h5 {
    font-weight: 800;
    font-size: 30px;
    line-height: 34px;
  }
  p {
    font-weight: 500;
    font-size: 18px;
    color: rgba(239, 233, 255, 0.6);
  }
  .bg-light-box {
    background: rgba(239, 233, 255, 0.03);
    backdrop-filter: blur(93px);
    border-radius: 12px;
    h4 {
      font-weight: 800;
      font-size: 32px;
    }
    span {
      font-weight: 500;
      font-size: 14px;
      color: rgba(239, 233, 255, 0.6);
    }
  }
  a {
    font-weight: 500;
    font-size: 20px;
    color: #564ece;
    text-decoration: none;
  }
}

.video-footers {
  background: rgba(32, 32, 32, 0.42);
  backdrop-filter: blur(45px);
  margin-top: -56px;
  .logo-nft {
    font-style: italic;
    font-weight: 300;
    font-size: 18px;
  }
  .Available-nft {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.5);
  }
  .link-nft.text-white {
    a {
      font-weight: normal;
      font-size: 18px;
      color: #fff;
    }
  }
}

.let-me-know {
  .darkBlk {
    min-width: 640px;
    .card {
      min-width: 100%;
      h4 {
        font-weight: normal;
        font-size: 21px;
        line-height: 22px;
        width: 290px;
      }
    }
  }
  .let-meknow-text {
    h3 {
      font-weight: normal;
      font-size: 46px;
      line-height: 52px;
      color: #ffffff;
    }
    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 30px;
      color: rgba(222, 217, 217, 0.58);
    }
    ul {
      li {
        list-style: none;
        padding-right: 20px;
      }
    }
    .cont-box {
      background: linear-gradient(
        93.61deg,
        #0b0d19 0.27%,
        rgba(17, 19, 30, 0.31) 99.73%
      );
      box-shadow: 6px 19px 62px -8px rgba(0, 0, 0, 0.13);
      border-radius: 25px;
      margin-left: -30px;
      h4 {
        // font-weight: 800;
        font-size: 32px;
      }
      span {
        font-weight: 300;
        font-size: 14px;
        color: rgba(239, 233, 255, 0.6);
      }
    }
  }
}

.earn-sharing {
  .earn-sharing-btn {
    background: rgba(86, 78, 206, 0.24);
    backdrop-filter: blur(4px);
    border-radius: 20px;
    margin-bottom: -30px;
    border: 2px solid rgba(86, 78, 206, 0.24);
    &:hover,
    &:focus {
      border: 2px solid rgba(86, 78, 206, 0.24);
      box-shadow: none;
    }
    span {
      width: 46px;
      height: 46px;
      background: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(8px);
      border-radius: 14px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
  span {
    label {
      font-weight: 500;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.5);
    }
    font-weight: normal;
    font-size: 21px;
  }
}

.Access-Reward-Tokens {
  &.detailSection {
    .rgtTopSec {
      h2 {
        color: #000;
        font-weight: 600;
        font-size: 30px;
        a {
          font-weight: normal;
          font-size: 21px;
          text-decoration-line: underline;
          color: #564ece;
        }
      }
      .form-control {
        color: #151414;
      }
      p {
        color: rgba(0, 0, 0, 0.66);
        font-weight: 500;
        font-size: 16px;
      }
      form {
        label {
          color: rgba(0, 0, 0, 0.66);
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
    .custom-radios input[type="checkbox"] + label span {
      background: linear-gradient(
        134.22deg,
        rgba(86, 78, 206, 0.25) 9.27%,
        rgba(0, 148, 255, 0.25) 142.83%
      );
    }
    .accordionBlkRight.accordion .accordion-item .accordion-button {
      h3 {
        line-height: 24px;
        margin: 0;
      }
      h2 {
        line-height: 30px;
        margin: 0;
      }
      ul {
        margin-top: 0px;
      }
    }
    .accordionBlkRight.accordion .accordion-item .accordion-body {
      ul {
        margin-bottom: 30px;
        padding-left: 10px;
      }
      .rewardsImg {
        margin-bottom: 40px;
      }
    }
  }
}

.Reasons-to-Purchase {
  background: #ffffff;
  box-shadow: 6px 19px 62px -8px rgb(0 0 0 / 13%);
  border-radius: 25px;
  h4 {
    font-weight: 600;
    font-size: 30px;
    color: #000000;
  }
  li {
    font-weight: normal;
    font-size: 21px;
    line-height: 30px;
    color: #000000;
    margin: 13px 0px;
  }
}

.overviews {
  h4 {
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: #000000;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.58);
  }
  li {
    list-style: none;
  }
}

.details {
  h3 {
    font-weight: 600;
    font-size: 30px;
    color: #000000;
  }
  .detail-table {
    background: linear-gradient(
      93.61deg,
      #ffffff 0.27%,
      rgba(239, 240, 247, 0.31) 99.73%
    );
    box-shadow: 6px 19px 62px -8px rgb(0 0 0 / 13%);
    border-radius: 25px;
    .row-table-de {
      background: linear-gradient(
        93.61deg,
        #ffffff 0.27%,
        rgba(239, 240, 247, 0.31) 99.73%
      );
      box-shadow: 6px 19px 62px -8px rgb(0 0 0 / 13%);
      border-radius: 22px 22px 0px 0px;
      ul {
        li {
          margin-top: 0px;
        }
      }
    }
    ul {
      justify-content: space-between;
      li {
        list-style: none;
        width: 215px;
        padding: 25px;
        border-right: 1px solid rgb(86 78 206 / 25%);
        margin-top: -15px;
        h4 {
          font-weight: normal;
          font-size: 30px;
          color: #564ece;
        }
        span {
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.5);
        }
        &:last-child {
          border-right: none;
        }
      }
    }
  }
  .detailSection {
    .accordionBlk.accordion {
      .accordion-item {
        background: linear-gradient(
          93.61deg,
          #ffffff 0.27%,
          rgba(239, 240, 247, 0.31) 99.73%
        );
        box-shadow: 6px 19px 62px -8px rgba(0, 0, 0, 0.13);
        .accordion-button {
          font-weight: 600;
          font-size: 21px;
          color: #262626;
          &::after {
            filter: invert(100%) sepia(9%) saturate(2%) hue-rotate(195deg)
              brightness(34%) contrast(101%);
          }
        }
        ul {
          li {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #000000;
          }
        }
      }
    }
  }
}

.reward-boxs {
  .update-na {
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    color: #564ece;
  }
  &.details .detailSection .accordionBlk.accordion .accordion-item {
    .accordion-button {
      font-weight: 500;
      font-size: 48px;
      line-height: 38px;
    }
    ul {
      li {
        &.d-revert {
          display: revert;
          margin-left: 18px;
        }
      }
    }
  }
}

.update-box {
  border: 2px solid #564ece;
  box-sizing: border-box;
  box-shadow: 6px 19px 62px -8px rgb(0 0 0 / 13%);
  border-radius: 25px;
  h5 {
    font-weight: 500;
    font-size: 36px;
    line-height: 40px;
    color: #000000;
  }
  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.58);
  }
  .date-tex {
    font-weight: 500;
    font-size: 18px;
    color: #564ece;
    margin-bottom: 20px;
  }
}

.comment-box {
  background: #ffffff;
  box-shadow: 6px 19px 62px -8px rgba(0, 0, 0, 0.13);
  border-radius: 31px;
  textarea.form-control {
    border: 2px solid #564ece;
    box-sizing: border-box;
    border-radius: 14px;
  }
}

.comment-all-box {
  h3 {
    font-weight: normal;
    font-size: 30px;
    color: #000000;
    margin: 0;
  }
  span {
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.7);
  }
  .date-time {
    font-weight: normal;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
  }
  p {
    font-weight: normal;
    font-size: 21px;
    line-height: 31px;
    color: #000000;
    margin-top: 20px;
  }
}

.StardomAwaitsYou {
  background: linear-gradient(87.37deg, #000000 5.75%, #2b2768 66.47%);
  h3 {
    font-weight: normal;
    font-size: 30px;
    line-height: 42px;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: rgba(222, 217, 217, 0.58);
  }
  .text-StardomAwaitsYou {
    a {
      font-weight: 500;
      font-size: 20px;
      text-decoration-line: underline;
      color: #ffffff;
      margin-left: 40px;
    }
  }
}

a.nav-link.link-he-nav {
  color: #585fcf;
}

@media screen and (max-width: 1380px) {
  .let-me-know {
    .darkBlk {
      min-width: 549px;
    }
    .let-meknow-text h3 {
      font-size: 29px;
    }
  }
  .StardomAwaitsYou {
    h3 {
      font-weight: normal;
      font-size: 26px;
      line-height: 42px;
    }
    p {
      font-size: 13px;
    }
    a {
      font-size: 13px;
    }
    button.btn.btn-primary {
      font-size: 13px;
      padding: 10px 20px;
    }
  }
}

@media screen and (max-width: 1100px) {
  .let-me-know {
    .darkBlk {
      min-width: 488px;
    }
    .let-meknow-text {
      .cont-box {
        margin-left: 0px;
        h4 {
          font-size: 20px;
        }
        span {
          font-size: 11px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .earn-sharing {
    span {
      font-weight: normal;
      font-size: 13px;
      label {
        font-size: 12px;
      }
    }
    .earn-sharing-btn {
      font-size: 12px;
      margin-bottom: 20px;
      span {
        width: 38px;
        height: 38px;
      }
    }
  }
  .img-aw {
    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
  a.nav-link.link-he-nav {
    padding-left: 0px !important;
    font-size: 10px;
    padding-right: 0;
  }
  .wrap-mo {
    flex-wrap: wrap;
  }
  .video-text-left {
    min-width: 100%;
    height: auto;
    h5 {
      font-size: 18px;
      line-height: 22px;
    }
    p {
      font-size: 12px;
    }
    .bg-light-box {
      h4 {
        font-size: 20px;
      }
      span {
        font-size: 11px;
      }
    }
    a {
      font-size: 15px;
    }
  }
  .video-boxs-inft {
    padding: 40px 0px;
  }
  .Available-nft {
    margin-top: 10px;
  }
  .link-nft {
    margin-top: 10px;
  }
  .let-me-know {
    .darkBlk {
      min-width: 100%;
    }
    .let-meknow-text {
      p {
        font-size: 12px;
      }
      .cont-box {
        margin-left: 0px;
        h4 {
          font-size: 20px;
        }
        span {
          font-size: 11px;
        }
      }
    }
  }
  .detailSection .darkBlk .card {
    h4 {
      font-size: 19px;
      line-height: 28px;
      width: 262px;
    }
    .card-body .headerRgt {
      img {
        width: 40px;
      }
    }
  }
  .update-box {
    h5 {
      font-weight: 500;
      font-size: 24px;
      line-height: 40px;
      color: #000000;
    }
    p {
      font-size: 12px;
    }
  }
  .comment-all-box p {
    font-size: 12px;
  }
  .comment-all-box h3 {
    font-size: 26px;
  }
  .comment-all-box span {
    font-size: 13px;
  }
  .reward-boxs.details
    .detailSection
    .accordionBlk.accordion
    .accordion-item
    .accordion-button {
    font-size: 23px;
  }
  .let-me-know {
    padding-bottom: 90px;
    .w-50 {
      width: 100% !important;
    }
  }
}

//-----------Inft image tem css ---------------//
.img-banner {
  position: relative;
  &.categoryBanner {
    background: #04050a;
    height: auto;
    .icon-image-tem {
      width: 532px;
      height: 159px;
      background: rgba(0, 0, 0, 0.39);
      backdrop-filter: blur(23px);
      border-radius: 29px;
      .icon-box-tm {
        text-align: center;
        a {
          color: #fff;
          font-weight: 500;
          font-size: 18px;
          line-height: 40px;
          text-decoration: none;
        }
      }
    }
    .video-text-left {
      height: 439px;
      .bg-light-box {
        background: rgb(239 233 255 / 7%);
      }
    }
    .image-banner-boxs-inft {
      width: 670px;
    }
  }
  &::after {
    content: "";
    background: url("./img/shadow-img-im.png");
    position: absolute;
    width: 849.27px;
    height: 740.88px;
    width: 994.27px;
    height: 1027.88px;
    top: 0;
    left: 0;
  }
}

.image-banner-tex {
  width: 561px;
  a {
    font-weight: 500;
    font-size: 20px;
    color: #564ece;
    text-decoration: none;
  }
}

.let-me-know {
  &.Burn-Fat {
    .let-meknow-text .cont-box {
      margin-right: -30px;
      margin-left: 0px;
    }
  }
}

@media screen and (max-width: 600px) {
  .img-banner {
    &.categoryBanner {
      .icon-image-tem {
        width: 100%;
        img {
          width: 34px !important;
        }
      }
      .video-text-left {
        height: auto;
      }
      .image-banner-boxs-inft {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
  .let-me-know.Burn-Fat .let-meknow-text .cont-box {
    margin-right: 0;
  }
}

.video-thm-detail {
  background: rgba(28, 28, 28, 0.79);
  backdrop-filter: blur(8px);
  border-radius: 0px 0px 12px 12px;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  h6 {
    font-weight: 500;
    font-size: 25px;
    margin: 0;
  }
  p {
    font-weight: 500;
    font-size: 16px;
    color: rgba(239, 233, 255, 0.6);
  }
}

@media screen and (max-width: 600px) {
  .open-btn-vi {
    img {
      width: auto !important;
    }
  }
  .video-thm-detail {
    img {
      width: auto !important;
    }
    h6 {
      font-size: 18px;
    }
    p {
      font-size: 12px;
    }
  }
}

.leMeKnow {
  input.form-check-input {
    box-shadow: none;
    background: #0b0d19;
    width: 22px;
    height: 22px;
    margin-top: 3px;
    margin-right: 12px;
    border: 2px solid #585fcf;
    border-radius: 0;
  }
}

.btn-hover-blue:hover {
  color: #5a52ff !important;
}

.share-model {
  .modal-dialog {
    max-width: 630px;
    .modal-content {
      height: 376px;
      background: #16122a;
      box-shadow: 0px 4px 80px rgb(0 0 0 / 25%);
      border-radius: 15px;
      padding: 40px;
      top: 118px;
      .follow-link {
        margin-bottom: 25px;
        ul li {
          list-style: none;
          padding-right: 10px;
          a {
            width: 42px;
            height: 43px;
            background: #564ece;
            border-radius: 8px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .modal-header {
        border: none;
        padding: 0;
        align-items: baseline;
        .modal-title {
          color: #fff;
          width: 450px;
        }
        button.btn-close {
          filter: invert(100%) sepia(7%) saturate(2%) hue-rotate(195deg)
            brightness(217%) contrast(193%);
          padding: 0;
          opacity: 1;
          &:focus {
            box-shadow: none;
          }
        }
      }
      .btn.btn-primary {
        border-radius: 0;
        padding: 0 40px;
        height: 65px;
        &:hover {
          background: #564ece;
        }
      }
      .form-control {
        padding: 0px 192px 0px 20px;
        height: 65px;
        border-radius: 0;
        background: #ffffff17;
        border: none;
        color: #fff;
        font-size: 18px;
      }
      a {
        color: #564ece;
        font-size: 20px;
      }
    }
  }
}

.modal-backdrop.show {
  opacity: 0.7;
}

.list-slider .slick-slider .collections-box {
  // width: 170px !important;
}
.main-margin {
  max-width: 1440px;
  margin: auto;
}

.Accesstokenbtn .btn span {
  width: 60px;
  background: rgba(86,78,206,.13);
  border-radius: 0 36.5px 36.5px 0;
  height: 100%;
  display: inline-block;
  padding: 13px 0 13px 10px;
}
.Accesstokenbtn .btn {
  padding: 12px 24px;
}
.tab-content .overview-stats .card {
  width: 100% !important;
}
.form-check-input{
  padding: 0;
}
