.toast-title {
    font-weight: bold;
}

.toast-message {
    -ms-word-wrap: break-word;
    word-wrap: break-word;
}

.toast-message a,
.toast-message label {
    color: #FFFFFF;
}

.toast-message a:hover {
    color: #CCCCCC;
    text-decoration: none;
}

.toast-close-button {
    position: relative;
    right: -0.3em;
    top: -0.3em;
    float: right;
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
    -webkit-text-shadow: 0 1px 0 #ffffff;
    text-shadow: 0 1px 0 #ffffff;
    opacity: 0.8;
    -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=80);
    filter: alpha(opacity=80);
    line-height: 1;
}

.toast-close-button:hover,
.toast-close-button:focus {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.4;
    -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=40);
    filter: alpha(opacity=40);
}

.rtl .toast-close-button {
    left: -0.3em;
    float: left;
    right: 0.3em;
}


/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/

button.toast-close-button {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
}

.toast-top-center {
    top: 0;
    right: 0;
    width: 100%;
}

.toast-bottom-center {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast-top-full-width {
    top: 0;
    right: 0;
    width: 100%;
}

.toast-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast-top-left {
    top: 12px;
    left: 12px;
}

.toast-top-right {
    top: 12px;
    right: 12px;
}

.toast-bottom-right {
    right: 12px;
    bottom: 12px;
}

.toast-bottom-left {
    bottom: 12px;
    left: 12px;
}

#toast-container {
    position: fixed;
    z-index: 999999;
    pointer-events: none;
    margin-top: 60px;
    /*overrides*/
}

#toast-container * {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

#toast-container>div {
    position: relative;
    pointer-events: auto;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px 15px 15px 65px;
    width: 360px;
    -moz-border-radius: 3px 3px 3px 3px;
    -webkit-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
    background-position: 15px center;
    background-repeat: no-repeat;
    -moz-box-shadow: 0 0 12px #999999;
    -webkit-box-shadow: 0 0 12px #999999;
    box-shadow: none;
    color: #FFFFFF;
    opacity: 1.8;
    -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=80);
    filter: alpha(opacity=80);
}

#toast-container>div.rtl {
    direction: rtl;
    padding: 15px 50px 15px 15px;
    background-position: right 15px center;
}

#toast-container>div:hover {
    -moz-box-shadow: 0 0 12px #000000;
    -webkit-box-shadow: 0 0 12px #000000;
    box-shadow: 0 0 12px #000000;
    opacity: 1;
    -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
    cursor: pointer;
}

#toast-container>.toast-info {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
}

#toast-container>.toast-error {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMzSURBVHgBzZg/TBNRHMe/7/VqwQCRDSJDEyFRFnGBBQwk4uCCiYGZuoNuxmAiJmjiJrBbZogJm4MkksAAk0w6QCImEtgwQoTa3p3v+8rVyp/2Xa/C+yRN79rX3vd+7/fvfgLH2G3t7vV83JcQA4CfxHngYw3CX4PrvmjcXNks/koUhCV7r0C6z9XCx7hIfPEGXkwJXfzBU/FXXO6jOuuADdCintNHkVJ/oC1niThCLdTEw93rvUlkc19hIwJ90su647AVBqv0/ZuwFn9AWuV7JxBJCctxEJHYjTY4XR2ItbdBXm3Sn/k/95Fb/YTshyV4WzuIgti91u2jApyuW6gZTel34n3fKYgRDXVKeKs+ptCDiWm4X9ZRCRUJrH02gsTwEPy9fWTSc8jMzCmr7f2zRrY0F26Clj2ceqteaYQltMDLr5/i0oN7WtjhdPqEsNOoGX2ohf5+9x6/nrxCGEL5IC9CcbQELWJKsJa/p38evJwy/q2xQAYBLRFWXLFI+mYiNYjswpL2TROM00zt2IgOglLiePHE8CBKiaTf0pKmGAmkk9Phyzl5/E4P4v23z/ye20vf5X8xPVVNYPxu/qKm21KKzMysfg/SUzmMBDqdHSqPbahct42o0IrMmbH2VqP1RgLp3PzjauFtbSu3aTZaG7nUFZNdWGaLVFWMBDJ6nc7yPpNJz8IE7gi32QSjLab/yZYmiPo6RIUZgRGcW10zW2+yKLeSj95EaqjkunJ5kATRy2RtgqEF13WK4cVLW1Gg6En2VJikaT3TjGBcSZik6Tssd2dBHwzy3Fni2OUcTJjXYmOBtCDbKm5jmFJVLC6o5WF6w1BphnfOLQ6saNLf6fWPUrp/ZLsVttGoqGEN+jv6EUXSusdbewoLgkY01OsaHKbNiiSQsP2qHRvVzyPE/byuOxXCVEJfIwwIWq3SOl6xwAAKiff36PQRRDitGUQ+hUchssD/jfXPxVKZ7xtsRY3hpMr787AUqaaunM1YK9BTI2HZuLG8qI4nYR+TnFfng8R1xvXY1RaohZpwFMV6YK1mwrDDkpPBfJonJ3qj/EiYU1efJeK8hpvMJPOMhyOXK/AHsFRoANQYtcYAAAAASUVORK5CYII=") !important;
}

#toast-container>.toast-success {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAN4SURBVHgBzZhNTBNBFMffbDGFyOcBwUSSAvEgRkQORjy1BmJPUhM9wd2rxBjjRUo8GDV+kBgT7nDyQPEiiqbVg3jCWoIeCIUEIkFMIIIBErfj/AdGl7WNs2Wt+082ne7HzG/fzHtv9jGyKRy/G+SmL8IM6iROASqAGKMk5yxJxmbfaOj63K5rqhGMRytLqKKXZ/hl+o/ixB5uGdSXCPWs4j9TcMVmWUK8ynHygBhjyQ1GIUAaOAHLeQUO4py3+DPUizYLx28FKOOfJS8qY4YMMv1R8qi4zxcxhLd6ZmrtYpw6Dc6phbyrgEEeVxHtQfuL/NRxsImaq+qoobSaaorL5fmlzW/yGFucosmVBdkuKCDAInWtdL7uhGwrqNTKvGyX7vNTc+UheUCDs+9oaHac8pFjQFjpdutF+QsoDPxi8SN9/7FF9pc4Xd1IXfVt1F1/Slr62sQTx9Zk4Vf3ue7NVrjY/HsamE5oPQdAgALOKaQjJ1FwANOFgzDFuF+9oFoWrgJ2CStggCExGKznVHgmNj8h+8D6dRUQnXbvTNFgnosdGph+LfuwOpcrgM1V2944lHYGB6s/O9Pz63kIVlThyTXA9tqj8vft1xnSVbsAUFZf2vjtFGPC4yEVglwBrCkpp/T68h+hJJcaRdC+cuRsVq9FH/jfUHZAqy/tNbhug8O5Rye7ZQaxn4enAuRm6mnWkAKLqqzjCmA2lYp1VCsGuSNgFKQ1jNz79JxmhNX3Ki1AWMH+xhgc0wcBsk1kDWucHF/OvV6RCnWDtRZgem1ZDmwPDVbIG8fOacVJ9AGLuwqYWt3eBGQLDQoSaw5wf4uTyM/Qy89T5Brg2M5mIFcGAOSFN4+1gjhyMpRaXSAdaQECbngnTV06HKR8ZU2Xrk4xhHWFTiMiTeFwKjyTT7rUBoQVVdCFFdVU6Qj341CB24kc7Qch+4YV1kBIybZhhVNh3ap7//mG1Sq1CVWasaRCAKm4iXPYIAyLJaKbKl0BVCDYqbTXNol8XbHroym99oUmhadm+xwoGGAh5PnvYkMU4ObIoxK1wqQhCoQj5FEJtqTBTTNGXpWotBqjHVcToorUTx6TKGr1j4Z65qST+H0sirIreUWcPmwJJjQlYEzUgv2iJuwFS8Jymz4W3FVEtyocfxAgk0dR2CxY7VBEEp6hEcbNGJac9dJPfcezjt144P4AAAAASUVORK5CYII=") !important;
}

#toast-container>.toast-warning {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important;
}

#toast-container.toast-top-center>div,
#toast-container.toast-bottom-center>div {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}

#toast-container.toast-top-full-width>div,
#toast-container.toast-bottom-full-width>div {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
}

.toast {
    background-color: #030303;
    font-size: 11px;
    font-weight: 500;
}

.toast-success {
    background-color: #111C30;
    font-size: 11px;
    font-weight: 500;
}

.toast-error {
    background-color: #111C30;
    font-size: 11px;
    font-weight: 500;
}

.toast-info {
    background-color: #2F96B4;
    font-size: 11px;
    font-weight: 500;
}

.toast-warning {
    background-color: #F89406;
    font-size: 11px;
    font-weight: 500;
}

.toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #000000;
    opacity: 0.4;
    -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=40);
    filter: alpha(opacity=40);
}


/*Responsive Design*/

@media all and (max-width: 240px) {
    #toast-container>div {
        padding: 8px 8px 8px 65px;
        width: 11em;
    }
    #toast-container>div.rtl {
        padding: 8px 65px 8px 8px;
    }
    #toast-container .toast-close-button {
        right: -0.2em;
        top: -0.2em;
    }
    #toast-container .rtl .toast-close-button {
        left: -0.2em;
        right: 0.2em;
    }
}

@media all and (min-width: 241px) and (max-width: 480px) {
    #toast-container>div {
        padding: 8px 8px 8px 65px;
        width: 18em;
    }
    #toast-container>div.rtl {
        padding: 8px 65px 8px 8px;
    }
    #toast-container .toast-close-button {
        right: -0.2em;
        top: -0.2em;
    }
    #toast-container .rtl .toast-close-button {
        left: -0.2em;
        right: 0.2em;
    }
}

@media all and (min-width: 481px) and (max-width: 768px) {
    #toast-container>div {
        padding: 15px 15px 15px 65px;
        width: 25em;
    }
    #toast-container>div.rtl {
        padding: 15px 65px 15px 15px;
    }
}