.owlList {
    .owl-carousel {
        padding-right: 10%;

        @media (max-width: 767px) {
            padding-right: 0;
        }

        .customCard {
            .imgWrap {
                height: 125px;
            }

            .card-title {
                margin-bottom: 15px;
                max-width: 100%;
            }

            .card-body {
                padding: 78px 17px 30px;
                background: #0b0d19;

                .profile {
                    width: 126px;
                    height: 126px;
                    border-radius: 100px;
                    border: solid 5px #04050a;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: -63px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            button.btn {
                background: #5a52ff;
            }
        }

        .owl-dots {
            position: absolute;
            top: -50px;
            right: 3%;

            .owl-dot {

                &.active,
                &:hover {
                    span {
                        background: #564ece !important;
                        width: 6px;
                        height: 6px;
                    }
                }

                span {
                    width: 4px;
                    height: 4px;
                    margin: 3px;
                    background: #ababab;
                }
            }
        }

        .owl-nav {
            position: absolute;
            right: 2%;
            top: 50%;
            transform: translateY(-50%);

            @media (max-width: 991px) {
                right: 0;
            }

            @media (max-width: 767px) {
                position: relative;
                top: inherit;
                transform: none;
                right: inherit;
            }

            button {
                background: #fff;
                width: 60px;
                height: 60px;
                border-radius: 100px;
                transition: all ease 0.4s;

                @media (max-width: 991px) {
                    width: 45px;
                    height: 45px;
                }

                &:hover {
                    background: #fff;
                    transition: all ease 0.4s;

                    span {
                        margin-left: 2px;
                        transition: all ease 0.4s;
                    }
                }

                &.owl-next {
                    span {
                        background: url("../img/arrowRight.png") no-repeat;
                        width: 27px;
                        height: 29px;
                        background-position: center center;
                        background-size: contain;
                        color: transparent;
                        display: inline-block;
                    }
                }

                &.owl-prev {
                    display: none;
                }
            }
        }
    }
}

.upFChar {
    text-transform: capitalize !important;
}

.text-input {
    padding: 0.3rem 0.5rem;
    background: rgba(196, 196, 196, 0.1);
    border-radius: 5px;
    font-weight: normal;
    font-size: 16px;
    text-align: right;
    color: #ffffff;
    margin-right: 1rem;
    max-width: 119px;
}