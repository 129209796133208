.header {
    .FilterBtn {
        max-width: 151px;
        @media (max-width: 767px) {
            max-width: 100%;
        }
        .dropdown-toggle {
            text-align: center;
            color: #fff;
            // color: #858585;
            border: 1px solid #c4c4c4;
            height: 52px;
            border-radius: 100px;
            padding: 8px 10px;
            width: 100%;
            img {
                margin-right: 12px;
            }
            &:hover,
            &:active,
            &:focus {
                background: #564ece;
                border-color: #564ece;
                color: #ffffff;
            }
            &::after {
                display: none;
            }
        }
    }
    .input-group {
        position: relative;
        .dropdown {
            flex: 1;
        }
        .dropdown-toggle {
            padding-left: 0;
        }
    }
    .dropdown-toggle {
        padding: 10px 50px 10px 30px;
        flex: 1;
        text-align: left;
        width: 100%;
        color: #ffffff;
        font-size: 16px;
        box-shadow: none;
        &::after {
            position: absolute;
            // right: 28px;
            top: 32%;
            transform: translateY(-50%);
            border: solid rgba(133, 133, 133, 1);
            border-width: 0 1px 1px 0;
            display: inline-block;
            padding: 5px;
            margin-left: 8px;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
        }
    }
    .show>.btn-success.dropdown-toggle {
        background-color: #564ece;
        border-color: #564ece;
        &::after {
            border-color: #ffffff;
        }
    }
    .dropdown-menu {
        border: 1px solid rgba(255, 255, 255, 0.07);
        background: rgb(34 34 36 / 50%);
        backdrop-filter: blur(20px);
        border-radius: 16px;
        left: auto !important;
        right: 0 !important;
        a {
            border-bottom: 1px solid rgba(255, 255, 255, 0.07);
            color: #ffffff;
            transition: all ease 0.4s;
            padding: 10px 28px;
            &:last-child {
                border-bottom: 0;
            }
            &:hover,
            &:active,
            &:focus,
            &.active {
                transition: all ease 0.4s;
                color: rgba(86, 78, 206, 1);
                background: transparent;
            }
        }
    }
    .input-group-text {
        padding-left: 18px;
    }
    .dropdown-toggle,
    input,
    .input-group-text,
    button,
    .form-control:focus {
        outline: none;
        box-shadow: none;
    }
    .input-group,
    .dropdown-toggle {
        // background-color: rgba(196, 196, 196, 0.37);
        // border-radius: 26px;
        // border: solid 1px transparent;
        // height: 52px;
        &:hover,
        &:focus {
            // border-color: #564ece;
            box-shadow: none !important;
        }
        .dropdown-toggle,
        input,
        .input-group-text {
            background-color: transparent;
            border: none;
        }
    }
}

.watch-tut {
    display: flex;
    justify-content: flex-start;
    border: 1px solid #564ECE;
    box-sizing: border-box;
    border-radius: 36.5px;
    overflow: hidden;
    font-size: 20px;
    color: #FFFFFF;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding-left: 1.5rem;
    >div {
        span {
            display: inline-block;
            padding: 0.5rem 1rem;
            background: rgba(86, 78, 206, 0.1);
            svg {
                path {
                    fill: #FFFFFF;
                }
            }
        }
    }
    &:hover {
        cursor: pointer;
        background-color: #564ece;
        >div {
            span {
                display: inline-block;
                padding: 0.5rem 1rem;
                background: #564ece;
                svg {
                    path {
                        fill: #FFFFFF;
                    }
                }
            }
        }
    }
}

.sub-header {
    padding: 1rem;
    margin-top: -1.6rem;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    background-color: #070811;
    display: flex;
    justify-content: space-between;
    .r-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .watch-tut {
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        border: 1px solid #564ECE;
        box-sizing: border-box;
        border-radius: 36.5px;
        overflow: hidden;
        font-size: 20px;
        color: #FFFFFF;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        padding-left: 1.5rem;
        >div {
            span {
                display: inline-block;
                padding: 0.5rem 1rem;
                background: rgba(86, 78, 206, 0.1);
                svg {
                    path {
                        fill: #FFFFFF;
                    }
                }
            }
        }
        &:hover {
            background-color: #564ece;
            >div {
                span {
                    display: inline-block;
                    padding: 0.5rem 1rem;
                    background: #564ece;
                    svg {
                        path {
                            fill: #FFFFFF;
                        }
                    }
                }
            }
        }
    }
}

.r-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 1024px) {
    .header {
        .dropdown-toggle {
            font-size: 12px;
        }
    }
}

@media screen and (max-width: 500px) {
    .sub-header {
        padding: 1.5rem;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .r-div {
            flex-direction: column;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .watch-tut {
            flex-direction: column;
            display: flex;
            justify-content: flex-start;
            >div {
                span {
                    display: inline-block;
                }
            }
        }
    }
}