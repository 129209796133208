.staking-table {
    .table-heading {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        .cta {
            button {
                border: 0px;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                background: transparent;
                border-radius: 26px;
                padding: 0.5rem 1.5rem;
                margin: 1rem;
                box-shadow: 0px 3px 23px rgba(86, 78, 206, 0.11);
                background: rgba(239, 233, 255, 0.03);
                border-radius: 52px;
                &:nth-child(1) {
                    margin-left: 0px;
                }
                &.active {
                    background: linear-gradient(89.27deg, #FC1885 0.23%, #564ECE 0.24%, #561EEF 99.74%);
                }
            }
        }
        .iNFT-purchase-value {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #fff;
            span {
                color: #564ECE;
            }
        }
    }
    .MuiPaper-root {
        background: transparent;
    }
    .MuiToolbar-regular {
        // min-height: 0px;
    }
    .MuiTable-root {
        .MuiTableHead-root {
            .MuiTableRow-root {
                background-color: rgba(255, 255, 255, 0.02);
                .MuiTableCell-root {
                    border-bottom: 0px solid rgba(224, 224, 224, 1) !important;
                }
            }
        }
        .MuiTableBody-root {
            .MuiTableRow-root {
                &:nth-child(even) {
                    background-color: rgba(255, 255, 255, 0.02);
                }
                .MuiTableCell-root {
                    border-bottom: 0px solid rgba(224, 224, 224, 1) !important;
                }
            }
        }
    }
    .col-name {
        min-width: 300px
    }
    .box {
        // min-height: 200px;
        // padding: 1rem 2rem;
        overflow: hidden;
        background: #0B0C11;
        box-shadow: 0px 14px 34px rgba(21, 17, 28, 0.35);
        border-radius: 12px;
    }
    @media screen and (max-width: 600px) {
        .table-heading {
            flex-direction: column;
            .cta {
                button {
                    margin: 0px;
                    margin-bottom: 10px;
                    padding: 0.5rem;
                    font-size: 14px;
                }
            }
        }
        .box {}
    }
}