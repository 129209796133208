.banner-bg {
    background-image: url('../img/creator-hub/creation-bg2.png');
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
}

.creation-success {
    .banner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        // min-height: 80vh;
        h4 {
            font-size: 30px;
            color: #fff;
            font-weight: 300;
        }
        .status {
            font-size: 21px;
            color: #FFFFFF;
        }
    }
    .create-cards {
        background-image: url('../img/creator-hub/wave-bg.png');
        background-position: center bottom;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: relative;
        padding: 5rem 0 8rem;
        h3 {
            font-size: 24px;
            color: #ffffff;
        }
        p {
            font-size: 16px;
            color: #FFFFFF61;
            letter-spacing: 1px;
        }
    }
    .other-stars {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        min-height: 500px;
        background-image: url('../img/creator-hub/other-star-bg.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        h2 {
            font-size: 43px;
            color: #ffffff;
        }
    }
}

.about-training-center {
    display: flex;
    flex-direction: row;
    .img {
        position: relative;
        .logo-tc {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            font-weight: 500;
            letter-spacing: 1px;
            color: #ffffff;
        }
    }
    h2 {
        font-weight: 600;
    }
}

@media screen and (max-width: 2560px) {
    .creation-success {
        .banner {}
    }
}

@media screen and (max-width: 1563px) {
    .creation-success {
        .banner {}
    }
}

@media screen and (max-width: 1440px) {
    .creation-success {
        .banner {}
    }
}

@media screen and (max-width: 1024px) {
    .buck-collecton-placehlder {
        >div {
            img {
                min-width: 250px;
            }
        }
    }
    .review-collection {
        .detail-img {
            img {
                width: 250px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .creation-success {
        .create-cards {
            padding: 3rem 0 2rem;
            .single-card {
                display: flex;
                height: 100%;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: space-between;
            }
        }
        .shape-bg-section {
            margin-top: 0px;
        }
    }
    .buck-collecton-placehlder {
        >div {
            img {
                min-width: 80%;
            }
        }
    }
    .review-collection {
        .detail-img {
            img {
                width: 100%;
            }
        }
        .text-detail {
            padding: 2rem;
            margin-top: 25px;
            .edit-field-box {
                position: relative;
                .field {
                    padding: 2.5rem !important;
                    flex-direction: column;
                    >div {
                        margin-bottom: 2rem;
                    }
                }
                .edit-btn {
                    padding-top: 2.5rem !important;
                    padding-right: 2.5rem !important;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {}

@media screen and (max-width: 561px) {
    .banner-bg {
        background-image: url('../img/creator-hub/creation-bg2.png');
        background-position: top left;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
    }
    .buck-collecton-placehlder {
        >div {
            width: 80%;
            margin-top: 3rem;
            img {
                // width: 80%;
                min-width: auto;
            }
        }
        &.bucks {
            >div {
                width: 80%;
                margin-top: 3rem;
                img {
                    // width: 80%;
                    min-width: auto;
                }
            }
        }
    }
    .buck-collecton-placehlder {
        >div {
            img {}
        }
    }
    .review-collection {
        .detail-img {
            img {}
        }
        .text-detail {
            padding: 1.5rem;
            margin-top: 25px;
            .edit-field-box {
                position: relative;
                .field {
                    padding: 2rem !important;
                    flex-direction: column;
                }
                .edit-btn {
                    padding-top: 2rem !important;
                    padding-right: 2rem !important;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
        }
    }
    .creation-success {
        .create-cards {
            padding: 2rem 0 0rem;
        }
        .shape-bg-section {
            margin-top: 0px;
        }
    }
    .about-training-center {
        display: flex;
        flex-direction: column;
        .img {
            position: relative;
            img {
                width: 100%;
                margin-bottom: 3rem;
            }
            .logo-tc {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: center;
                align-items: center;
                font-size: 30px;
                font-weight: 500;
                letter-spacing: 1px;
                color: #ffffff;
            }
        }
    }
}