.token-form {
    padding: 2rem 4rem;
    .event-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        font-size: 21px;
        color: #ABABAB;
        span {
            color: #564ECE
        }
    }
    .form-holder {
        width: 70%;
        .cta {
            button {
                margin-right: 1.5rem;
                // padding: 0.5rem 1.5rem;
                font-weight: normal;
                font-size: 20px !important;
            }
        }
        .download-links {
            a {
                display: block;
                color: #564ECE;
                margin-bottom: 1rem;
                font-weight: 600;
                font-size: 21px;
            }
        }
    }
    .claime-form {
        position: relative;
        .info-modal {
            position: absolute;
            right: 1rem;
            top: 1rem;
        }
        .claime-form-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title {
                font-weight: normal;
                font-size: 30px;
                color: #FFFFFF;
            }
            .status {
                font-style: italic;
                font-weight: normal;
                font-size: 16px;
                text-align: right;
                color: #7AFFFF;
            }
        }
        .claime-form-body {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .detail {
                font-style: normal;
                font-weight: 600;
                font-size: 21px;
                color: #564ECE;
            }
            .remaining {
                font-weight: 500;
                font-size: 21px;
                text-align: right;
                color: #564ECE;
            }
        }
    }
    .form-control {
        border: 2px solid #564ECE;
        box-sizing: border-box;
        border-radius: 36.5px;
        background: transparent;
        padding: 0.5rem 1rem;
        color: #FFFFFF;
    }
    // .react-select-container {
    //     background: rgba(196, 196, 196, 0);
    //     border: 2px solid #564ECE;
    //     box-sizing: border-box;
    //     border-radius: 36.5px;
    //     background: transparent;
    //     .react-select__control {
    //         border-radius: 26px;
    //         // background-color: #17181D;
    //         border-color: hsla(0, 100%, 50%, 0);
    //         .react-select__value-container {
    //             // background-color: #17181d00;
    //             border-top-left-radius: 26px;
    //             border-bottom-left-radius: 26px;
    //             padding: 0.25rem;
    //             display: flex;
    //             flex-direction: row;
    //             flex-wrap: nowrap;
    //             .react-select__single-value {
    //                 padding-left: 1rem;
    //                 color: #FFFFFF;
    //             }
    //             .react-select__multi-value {
    //                 background-color: #564ECE;
    //                 padding: 0.25rem 1rem 0.25rem 0.75rem;
    //                 border-radius: 20.5px;
    //                 min-width: 100px;
    //                 position: relative;
    //                 .react-select__multi-value__label {
    //                     min-width: 100%;
    //                     box-sizing: border-box;
    //                     color: #ffffff;
    //                 }
    //                 .react-select__multi-value__remove {
    //                     position: absolute;
    //                     right: 5px;
    //                     top: 0px;
    //                     bottom: 0px;
    //                     color: #fff;
    //                     height: 100%;
    //                     &:hover {
    //                         color: #fff;
    //                         background: transparent;
    //                     }
    //                 }
    //             }
    //             .react-select__placeholder {
    //                 margin-left: 1rem;
    //             }
    //             .react-select__input-container {
    //                 min-width: 100px;
    //             }
    //         }
    //     }
    //     .react-select__menu {
    //         backdrop-filter: blur(20px);
    //         border-radius: 16px;
    //         background: rgb(48, 48, 48);
    //         overflow: hidden;
    //         .react-select__menu-list {}
    //         .react-select__option {
    //             background: rgba(0, 0, 0, 0);
    //             color: #FFFFFF;
    //             padding: 1rem 1.5rem;
    //             margin: 0px;
    //             &:hover {
    //                 background: #564ECE;
    //             }
    //         }
    //     }
    // }
    &.restrict {
        padding: 0px;
        text-align: center;
        // background: rgba(86, 78, 206, 0.58);
        // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        // backdrop-filter: blur(13px);
        /* Note: backdrop-filter has minimal browser support */
        // background-image: url('../img/Hub/restrictedd-bg.png');
        .restricted-access {
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            position: relative;
            img {
                border-radius: 31px;
                overflow: hidden;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .info {
                position: absolute;
                // top: 0;
                // bottom: 0;
                h2 {
                    font-weight: 500;
                    font-size: 48px;
                    line-height: 44px;
                    /* or 92% */
                    text-align: center;
                    font-feature-settings: 'pnum' on, 'lnum' on;
                    color: #FFFFFF;
                }
                p {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    /* identical to box height, or 125% */
                    text-align: center;
                    color: #FFFFFF;
                }
            }
        }
    }
    @media screen and (max-width: 2560px) {}
    @media screen and (max-width: 1563px) {}
    @media screen and (max-width: 1440px) {}
    @media screen and (max-width: 1024px) {}
    @media screen and (max-width: 1024px) {}
    @media screen and (max-width: 768px) {
        padding: 1rem 2rem;
        .form-holder {
            width: 75%;
            .cta {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;
                button {
                    margin-right: 0.5rem;
                    margin-bottom: 0.5rem;
                    font-size: 16px !important;
                }
            }
        }
    }
    @media screen and (max-width: 600px) {}
    @media screen and (max-width: 561px) {
        padding: 1rem 1.5rem;
        .form-holder {
            width: 100%;
            .cta {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;
                button {
                    margin-right: 0.5rem;
                    margin-bottom: 0.5rem;
                    font-size: 16px !important;
                }
            }
            .download-links {
                a {
                    display: block;
                    color: #564ECE;
                    margin-bottom: 1rem;
                    font-weight: 600;
                    font-size: 21px;
                }
            }
        }
        &.restrict {
            padding: 0px;
            text-align: center;
            .restricted-access {
                img {
                    height: 200px;
                }
                .info {
                    .lock-icon {
                        svg {
                            width: 50px;
                            height: 50px;
                        }
                    }
                    h2 {
                        font-size: 24px;
                        margin-top: 0px !important;
                        margin-bottom: 0px !important;
                    }
                    p {}
                }
            }
        }
    }
}