.connect-wallet {
    //-----------Starstake setup  css ---------------//
    .new_user_Account {
        height: calc(100vh - 56px);
        margin-top: 56px;
        display: flex;
        .left-sec {
            background-image: url("../img/wallet-setup-bg.png");
            background-position: left;
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;
            height: 100 vh;
            width: 50%;
            flex: 1;
            position: relative;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            flex-direction: row;
            flex-wrap: nowrap;
            img {
                // position: absolute;
                width: 50%;
                // bottom: 5rem;
                // margin: 0 auto;
                margin-bottom: 15rem;
            }
        }
        .right-sec {
            flex: 1;
            width: 50%;
        }
        .connect-user-new {
            height: 100% !important;
            overflow: scroll;
            padding-left: 4rem;
            padding-right: 12rem;
            h2 {
                font-weight: normal;
                font-size: 30px;
                span {
                    font-weight: 600;
                    color: #564ECE;
                }
            }
            h3 {
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                color: #564ECE;
            }
            p {
                font-style: normal;
                font-weight: 500;
                letter-spacing: 0.5px;
                font-size: 16px;
                color: rgba(255, 255, 255, 0.66);
                a,
                span {
                    font-weight: 500;
                    font-size: 16px;
                    color: #564ECE;
                    letter-spacing: 1px;
                }
            }
            .video-sec {
                .video-boxs-Tutorial {
                    img {
                        width: 100%;
                    }
                }
            }
            &.register-form {
                form {
                    label {
                        font-weight: 300;
                        font-size: 18px;
                        line-height: 40px;
                        color: #FFFFFF;
                        opacity: 0.21;
                    }
                    .form-control {
                        border: 1px solid #858585;
                        box-sizing: border-box;
                        box-shadow: 0px 0px 36px -11px rgba(0, 0, 0, 0.25);
                        border-radius: 31.5px;
                        background: transparent;
                        padding: 1rem 1.5rem;
                        color: #FFFFFF;
                        &:focus {
                            background: transparent;
                            border: 2px solid #564ECE;
                            box-sizing: border-box;
                            box-shadow: 0px 0px 36px -11px rgba(0, 0, 0, 0.25);
                            border-radius: 31.5px;
                        }
                    }
                    .error {
                        padding: 0.5rem 1.5rem;
                        color: rgb(179, 50, 50);
                    }
                }
            }
            &.fan-start {
                ul {
                    li {
                        font-style: normal;
                        font-weight: 500;
                        letter-spacing: 0.5px;
                        font-size: 16px;
                        color: rgba(255, 255, 255, 0.66);
                    }
                }
            }
            .cta {
                max-width: 250px;
                button {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                    span {
                        margin-left: 5px;
                        display: block;
                        width: 25px;
                        height: 25px;
                        &.fan {
                            background-image: url("../img/Emoji_Smiling.png");
                            background-position: center center;
                            background-repeat: no-repeat;
                        }
                        &.star {
                            background-position: center center;
                            background-repeat: no-repeat;
                            background-image: url("../img/Emoji_Star.png");
                        }
                    }
                }
            }
            a {
                color: #564ECE;
                text-decoration: none;
            }
        }
    }
    .height-heading {
        height: 36px;
    }
    @media screen and (max-width: 1100px) {
        .new_user_Account {
            .setup-bg-box {
                width: 100%;
                height: auto;
            }
        }
    }
    @media screen and (max-width: 991px) {
        .height-heading {
            height: auto;
            h2 {
                position: unset !important;
                font-size: 20px !important;
            }
        }
        .connect-user-new {
            h3 {
                font-size: 20px !important;
            }
            p {
                font-size: 13px !important;
            }
        }
        .video-boxs-Tutorial {
            svg {
                width: 100%;
                height: auto;
            }
        }
        .new_user_Account .setup-bg-box a {
            font-size: 14px !important;
        }
    }
    @media screen and (max-width: 768px) {
        .new_user_Account .setup-bg-box .btn {
            width: 100% !important;
        }
        .new_user_Account {
            height: auto;
            margin-top: 56px;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            .left-sec {
                background-image: url("../img/wallet-setup-bg.png");
                background-position: top;
                background-repeat: no-repeat;
                background-size: cover;
                overflow: hidden;
                height: 50vh;
                width: 100%;
                flex: auto;
                padding: 8rem 2rem 4 rem 2rem;
                img {
                    margin-bottom: 4rem;
                }
            }
            .right-sec {
                flex: auto;
                width: auto;
            }
            .connect-user-new {
                padding: 2rem;
            }
        }
    }
    @media screen and (max-width: 600px) {
        .new_user_Account .setup-bg-box .btn {
            width: 100% !important;
        }
        .new_user_Account {
            height: auto;
            margin-top: 56px;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            .left-sec {
                background-image: url("../img/wallet-setup-bg.png");
                background-position: top;
                background-repeat: no-repeat;
                background-size: cover;
                overflow: hidden;
                height: auto;
                width: 100%;
                flex: auto;
                padding: 8rem 2rem 2rem 2rem;
                img {
                    // position: absolute;
                    // width: 75%;
                    // bottom: 5rem;
                    // margin: 0 auto;
                    margin-bottom: 5px;
                }
            }
            .right-sec {
                flex: auto;
                width: auto;
            }
            .connect-user-new {
                padding: 2rem;
            }
        }
    }
}