$rhs-width: calc(100% - 269px);
.access-tokens {
    .access-tokens-section {
        .head-filter {
            display: flex;
            justify-content: space-between;
            h4 {
                font-size: 20px;
                color: #ffffff;
                .sec-heading {
                    font-size: 20px;
                    color: #ffffff;
                }
                span {
                    color: #564ECE;
                }
            }
        }
        .access-tokens-filter {
            .filter-row {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                // flex-flow: row wrap;
                align-items: center;
                align-content: center;
                justify-content: space-between;
                >div {
                    margin: 0px 1rem;
                    flex: 1;
                    flex-basis: 33.33%;
                    &:nth-child(1) {
                        margin-left: 0px;
                    }
                    &:nth-last-child(1) {
                        margin-right: 0px;
                    }
                }
            }
        }
        .cards-container {
            // background: red;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            // justify-content: flex-start;
            // width: calc(100% - 269px);
            .card-box {
                width: calc($rhs-width/4);
                width: calc(100% / 4);
                // flex: 1 0 calc(25% - 2rem);
                // background: #564ECE;
                &:nth-child(1) {
                    .card-holder {
                        margin-left: 0rem;
                    }
                }
                &:nth-last-child(1) {
                    .card-holder {
                        margin-right: 0rem;
                    }
                }
                .card-holder {
                    height: 100%;
                    margin: 0.5rem;
                    box-sizing: content-box;
                    // margin: 0.5rem;
                    .single-card {
                        display: flex;
                        flex-direction: column;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        height: 100%;
                        position: relative;
                        padding-bottom: 1rem;
                        border-radius: 25px;
                        border: 1px solid rgba(171, 171, 171, 0.38);
                        &:hover {
                            background: linear-gradient( 65.56deg, #893af2 12.91%, #393394 50.41%);
                            box-shadow: 6px 19px 62px -8px rgb(0 0 0 / 13%);
                            border: 1px solid #5336b3;
                            transition: 2s;
                            .view-claim {
                                a {
                                    color: #FFFFFF;
                                }
                            }
                        }
                        .token-img {
                            background: rgb(73, 73, 73);
                            // overflow: hidden;
                            border-radius: 10px;
                            margin: 1rem;
                            position: relative;
                            margin-bottom: 1rem;
                            &.test {
                                background-size: cover;
                                // min-height: 300px;
                                // background-image: url('https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60');
                            }
                            img {
                                // width: 100%;
                                width: 100%;
                                // width: auto;
                                // margin-top: 0.5rem;
                            }
                            .type-n-rare {
                                z-index: 99;
                                width: 100%;
                                bottom: -1rem;
                                position: absolute;
                                display: flex;
                                justify-content: space-between;
                                padding: 0px 0.5rem;
                                .token-type {
                                    display: flex;
                                    justify-content: space-between;
                                    div {
                                        background: #564ECE;
                                        border-radius: 20.5px;
                                        width: 25px;
                                        height: 25px;
                                        display: flex;
                                        align-content: center;
                                        align-items: center;
                                        justify-content: center;
                                        margin: 0px 0.25rem;
                                        svg {
                                            width: 14px;
                                            height: 14px;
                                        }
                                    }
                                }
                                .token-rarity {
                                    background: #564ECE;
                                    border-radius: 20.5px;
                                    display: flex;
                                    align-content: center;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 12px;
                                    font-weight: normal;
                                    padding: 0px 0.75rem;
                                    color: rgba(255, 255, 255, 0.88);
                                    svg {
                                        width: 16px;
                                        height: 16px;
                                    }
                                }
                            }
                        }
                        .info {
                            margin: 0px 1rem;
                            flex-direction: column;
                            .title {
                                font-size: 18px;
                                color: #FFFFFF;
                            }
                            .by {
                                font-size: 16px;
                                color: rgba(255, 255, 255, 0.28);
                            }
                        }
                        .view-claim {
                            margin: 0.5rem 1rem 0rem;
                            display: flex;
                            justify-content: space-between;
                            align-content: center;
                            align-items: center;
                            a {
                                color: #564ECE;
                            }
                            span {
                                font-weight: normal;
                                font-size: 15px;
                                text-align: right;
                                color: #ABABAB;
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 2560px) {
            .cards-container {
                .card-box {
                    // flex: 0 1 calc(20% - 2rem);
                    // width: calc(100% - 269px) / 20%;
                }
            }
        }
        @media screen and (max-width: 1563px) {
            .cards-container {
                .card-box {
                    // flex: 0 1 calc(25% - 2rem);
                    // width: calc(100% - 269px) / 25%;
                }
            }
        }
        @media screen and (max-width: 1440px) {
            .cards-container {
                .card-box {
                    //flex: 0 1 calc(33.33% - 2rem);
                    // width: calc(100% - 269px) / 33.33%;
                }
            }
        }
        @media screen and (max-width: 1024px) {
            .cards-container {
                .card-box {
                    width: calc(100% / 3);
                }
            }
        }
        @media screen and (max-width: 1024px) {
            .cards-container {
                .card-box {
                    width: calc(100% / 3);
                }
            }
        }
        @media screen and (max-width: 768px) {
            .cards-container {
                .card-box {
                    width: calc(100% / 2);
                }
            }
        }
        @media screen and (max-width: 600px) {
            .cards-container {
                .card-box {
                    width: calc(100% / 2);
                }
            }
        }
        @media screen and (max-width: 561px) {
            .access-tokens-filter {
                .filter-row {
                    flex-direction: column;
                    align-content: center;
                    >div {
                        margin: 0px;
                        margin: 0.5rem;
                        flex: 1 0 auto;
                        &:nth-child(1) {
                            margin-left: 0.5rem;
                        }
                        &:nth-last-child(1) {
                            margin-right: 0.5rem;
                            text-align: right;
                        }
                        width: 100%;
                        .react-select-container {}
                    }
                }
            }
            .cards-container {
                .card-box {
                    width: calc(100% / 1);
                }
            }
        }
    }
}