    //--------------- color code ---------------//
    $dark-color: #04050A;
    $dark1-color: #0B0D19;
    $blue-light-color: #5A52FF;
    $blue2-light-color: #564ECE;
    $blue3-light-color: #8984DA;
    $blue4-light-color: #7D74FF;
    $blue5-light-color: #585fcf;
    $blue6-light-color: #8379fc;
    $blue8-light-color: #8781E0;
    $white-color: #fff;
    $gray-color: #ACACAC;
    $gray2-color: #818181;
    $gray3-color: #616161;
    $gray5-color: #858585;
    $gray6-color: #ababab;
    $gray7-color: #C4C4C4;
    $gray8-color: #3b3b42;
    $gray12-color: #494E68;
    $black-color: #000000;
    $black3-color: #0a0c15;
    $black8-color: #0B0D1A;
    $black4-color: #1C1924;
    $color-B2FFD6: #B2FFD6;
    //--------------- Rgba color code ---------------//
    $white2-color: rgba(255, 255, 255, 1);
    $gray4-color: rgba(222, 217, 217, 0.58);
    $gray9-color: rgba(196, 196, 196, 0.1);
    $gray10-color: rgba(255, 255, 255, 0.5);
    $gray11-color: rgba(133, 133, 133, 1);
    $black2-color: rgba(0, 0, 0, 0.4);
    $black5-color: rgba(255, 255, 255, 0);
    $black6-color: rgba(0, 0, 0, 0.12);
    $black7-color: rgba(0, 0, 0, 1);
    $greenlight1-color:rgba(133, 255, 196, 1);
    $bluelight-color:rgba(92, 198, 255, 1);
    $par-color:rgba(188, 133, 255, 1);
    $blue7-light-color: rgba(86, 78, 206, 1);
    .main {}
    
    .bg-gray {
        background: #F2F2F2;
    }
    
    .main-content-wrapper {
        max-width: 1440px;
        margin: 0 auto;
        // margin-right: 80px;
    }
    
    .header-marg {
        margin-top: 58px;
    }
    
    .back-link {
        margin: 20px;
        a {
            display: inherit;
            svg {
                transition: all ease 0.4s;
                &:hover {
                    margin-left: -2px;
                    transition: all ease 0.4s;
                }
            }
        }
        h3 {
            font-weight: normal;
            font-size: 30px;
            color: #FFFFFF;
        }
    }
    
    .page-loader {
        background: rgba(0, 0, 0, 0.500);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99999;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .box {
        min-height: 200px;
        background: #0B0C11;
        box-shadow: 0px 14px 34px rgba(21, 17, 28, 0.35);
        border-radius: 32px;
        position: relative;
        .info-modal {
            position: absolute;
            top: 1rem;
            right: 1rem;
        }
    }
    
    .btn {
        &.btn-semi-dark {
            background: rgba(86, 78, 206, 0.11);
            color: #564ECE;
        }
        &.btn-white {
            background: #FFFFFF !important;
            color: #564ECE !important;
        }
    }
    
    .btn-link {
        color: #564ECE;
        &:hover {
            color: #564ECE;
        }
    }
    
    .btn.disabled,
    .btn:disabled,
    fieldset:disabled .btn {
        pointer-events: none;
        cursor: not-allowed;
        opacity: .45;
    }
    
    button {
        &.add-cart-btn {
            width: 122px;
            height: 36px;
            background: rgba(196, 196, 196, 0.1) !important;
            border-radius: 18px;
            font-weight: 500;
            font-size: 16px;
            color: rgba(255, 255, 255, 0.5) !important;
            border: none!important;
        }
    }
    
    .text-blue {
        color: #564ece;
    }
    
    .text-right {
        text-align: right;
    }
    
    .svg-blue {
        svg {
            path {
                fill: #564ece;
            }
        }
    }
    
    .text-gray {
        color: #ABABAB;
    }
    
    .blurr-bg {
        &::after {
            content: "";
            background-image: url('../img/blurr-bg.png');
            opacity: 0.5;
            top: 15%;
            left: 256px;
            bottom: 0;
            right: 0;
            position: absolute;
            z-index: -1;
        }
    }
    
    input {
        background: rgb(23 24 29);
        border-radius: 26px;
        padding: 0.5rem 1rem;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #FFF;
        border: 0px;
        outline: 0px;
    }
    
    .main-logo {
        svg {}
    }
    
    @media screen and (max-width: 2560px) {}
    
    @media screen and (max-width: 1563px) {}
    
    @media screen and (max-width: 1440px) {}
    
    @media screen and (max-width: 1024px) {}
    
    @media screen and (max-width: 1024px) {}
    
    @media screen and (max-width: 768px) {
        .blurr-bg {
            &::after {
                content: "";
                background-image: url('../img/blurr-bg.png');
                opacity: 0.5;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                position: absolute;
                z-index: -1;
                height: 100%;
            }
        }
    }
    
    @media screen and (max-width: 600px) {}
    
    @media screen and (max-width: 561px) {}