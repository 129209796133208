.stake-hub {
    .right-wrapper {
        .blurr-bg {
            &::after {
                content: "";
                background-image: url('../img/blurr-bg.png');
                opacity: 0.5;
                top: 15%;
                left: 256px;
                bottom: 0;
                right: 0;
                position: absolute;
                z-index: -1;
            }
        }
    }
    .sec-heading {
        font-style: normal;
        font-weight: normal;
        font-size: 21px;
        color: #FFFFFF;
    }
    .royalty-wallet-charts {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: stretch;
    }
    .box {
        // min-height: 200px;
        // padding: 1rem 2rem;
        background: #0B0C11;
        overflow: hidden;
        box-shadow: 0px 14px 34px rgba(21, 17, 28, 0.35);
        border-radius: 12px;
    }
    @media screen and (max-width: 600px) {
        .sec-heading {}
        .royalty-wallet-charts {
            flex-direction: column;
        }
        .box {}
    }
}

.pie-chart.stakehub-bals{
    .pai-chart {
        margin-top: -30px;
    }
   .chart-heading h3 {
        text-transform: uppercase;
        margin-top: 10px;
    }
    .chart-pie-text {
        p{
            font-weight: 300;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            letter-spacing: -0.03em;
            color: rgba(183, 189, 212, 0.65);
        }
    }
     .pie-chart-holder .bottom.bottoms {
        bottom: -38px;
    }
    .pie-chart-holder .bottom .info .per {
        font-weight: 300;
        font-size: 14.1521px;
        letter-spacing: -0.03em;
        color: #B7BDD4;
        padding: 0.3rem 0.7rem;
        span {
            font-weight: 400;
        }
    }
}
.card-bg-gray {
    background: rgba(239, 233, 255, 0.1) !important;
}