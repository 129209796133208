.nftb-selector {
    .sub-header-p {
        color: #FFFFFF;
        font-size: 16px;
        line-height: 21px;
        width: 70%;
    }
    .sub-hheader-links {
        .btn.btn-md {
            padding: 14px 24px;
            font-size: 14px;
            color: #564ece !important;
        }
        .btn.btn-outline-primary {
            padding: 14px 24px;
            font-size: 14px;
            border: 1.5px solid #5A52FF;
            color: #564ece !important;
        }
    }
    .nftb-cards {
        // display: flex;
        // justify-content: space-between;
        // flex-direction: row;
        // flex-wrap: nowrap;
        // overflow: auto;
        .slick-slider {
            width: 100%;
        }
        .collection-list-box {
            min-width: 240px;
            max-width: 240px;
            flex: 1;
            border: 1px solid rgba(171, 171, 171, 0.38);
            box-sizing: border-box;
            border-radius: 25px;
            margin: 0px 20px;
            padding: 1.25rem;
            .collections-img {
                img {
                    width: 100%;
                    height: 387px;
                    border-radius: 8px;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            .collection-text {
                font-size: 16px;
                line-height: 37px;
                font-weight: 500;
                color: #5A52FF;
                svg {
                    path {
                        fill: #564ece;
                    }
                }
                .check-mark {
                    width: 22px;
                    height: 22px;
                    margin-right: 0.5rem;
                    border-radius: 22px;
                    background: linear-gradient(134.22deg, rgba(86, 78, 206, 0.15) 9.27%, rgba(0, 148, 255, 0.15) 142.83%);
                }
            }
            &.selected {
                background: linear-gradient(65.56deg, #893AF2 12.91%, #393394 50.41%);
                box-shadow: 6px 19px 62px -8px rgba(0, 0, 0, 0.13);
                border-radius: 25px;
                border: 0px solid rgba(171, 171, 171, 0.38);
                .collection-text {
                    color: #FFFFFF;
                    svg {
                        path {
                            fill: #FFFFFF;
                        }
                    }
                    .check-mark {
                        width: 22px;
                        height: 22px;
                        margin-right: 0.5rem;
                        border-radius: 22px;
                        background: #FFFFFF;
                        background-image: url("../img/svg/selected.png");
                        background-position: center center;
                        background-repeat: no-repeat;
                    }
                }
            }
        }
    }
}

.bundle-summary {
    .box {
        overflow: hidden;
        // background: #33314B;
        .heading {
            background: rgba(86, 78, 206, 0.06);
            box-shadow: 6.15385px 19.4872px 63.5897px -8.20513px rgba(0, 0, 0, 0.13);
        }
        h3 {
            font-weight: 500;
            font-size: 18px;
            color: #FFFFFF;
            margin: 0px;
        }
        p {
            font-weight: normal;
            font-size: 16px;
            letter-spacing: 0.5px;
            // line-height: 22px;
            color: rgba(255, 255, 255, 0.66);
        }
        .bundle-img {
            width: 70%;
            margin-left: 25px;
            img {
                width: 100%;
            }
        }
        .summary {
            background: rgba(86, 78, 206, 0.06);
        }
        .in-bundle {
            background: rgba(86, 78, 206, 0.11);
            border-radius: 18px;
            margin-bottom: 1rem;
            .buck-img {
                width: 51px;
                img {
                    width: 100%;
                }
            }
            .rairty {
                font-weight: 500;
                min-width: 100px;
                font-size: 10.8036px;
                background: #564ECE;
                border-radius: 18.4562px;
                padding: 0.5rem 1rem;
                color: #FFFFFF;
            }
            .react-select-container {
                min-height: auto;
                margin: 0px 0.5rem;
            }
            .react-select-container.css-b62m3t-container .css-1s2u09g-control,
            .react-select-container.css-b62m3t-container .css-1pahdxg-control {
                padding: 0px 5px 0px 0px;
            }
            .css-qc6sy-singleValue {
                color: hsl(0, 0%, 100%);
            }
        }
    }
    .instructions {
        .heading {
            background: rgba(86, 78, 206, 0.06);
        }
        p {
            font-weight: normal;
            font-size: 16px;
            line-height: 27px;
            /* or 169% */
            color: rgba(255, 255, 255, 0.66);
        }
    }
}

@media screen and (max-width: 600px) {
    .nftb-selector {
        .nftb-cards {
            flex-direction: column;
            .collection-list-box {
                margin: 10px 10px;
            }
        }
    }
    .bundle-summary {
        .box {
            overflow: hidden;
            // background: #33314B;
            .heading {
                background: rgba(86, 78, 206, 0.06);
                box-shadow: 6.15385px 19.4872px 63.5897px -8.20513px rgba(0, 0, 0, 0.13);
            }
            h3 {
                font-weight: 500;
                font-size: 18px;
                color: #FFFFFF;
                margin: 0px;
            }
            p {
                font-weight: normal;
                font-size: 16px;
                letter-spacing: 0.5px;
                // line-height: 22px;
                color: rgba(255, 255, 255, 0.66);
            }
            .bundle-img {
                width: 70%;
                margin-left: 25px;
                img {
                    width: 100%;
                }
            }
            .summary {}
            .in-bundle {
                .buck-img {
                    margin-bottom: 1rem;
                    width: 100%;
                    img {
                        width: 100%;
                    }
                }
                .rairty {}
            }
        }
        .instructions {
            .heading {}
            p {}
        }
    }
}

.slides .slick-prev,
.slides .slick-next {
  position: absolute;
  top: 50%;
  z-index: 1;
}