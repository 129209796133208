.sb-wrapper {
    position: relative;
    .starter-buldle {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;
        // max-height: 350px;
        .text {
            background: linear-gradient(102.14deg, #564ECE 29.17%, #2B00CF 84.6%);
            border-radius: 20px;
            color: #fff;
            position: absolute;
            margin-right: 2rem;
            // right: 0px;
            // padding-right: 50%!important;
            .text-text {
                width: 45%;
                margin: 1.15rem 2rem;
                .btn-white {
                    background: #fff!important;
                    color: #564ECE;
                }
            }
        }
        .image {
            position: relative;
            right: 0px;
            width: 50%;
            margin-left: 45%;
            img {
                width: 115%;
            }
        }
        >div {
            flex: 1;
        }
    }
    @media screen and (max-width: 561px) {
        margin: 1rem;
        .starter-buldle {
            background: linear-gradient(102.14deg, #564ECE 29.17%, #2B00CF 84.6%);
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-content: center;
            align-items: center;
            // max-height: 350px;
            .text {
                background: transparent;
                color: #fff;
                position: relative;
                margin-right: 0rem;
                // right: 0px;
                // padding-right: 50%!important;
                .text-text {
                    width: 100%;
                    margin: 0rem 0rem;
                    .btn-white {
                        background: #fff!important;
                        color: #564ECE;
                    }
                }
            }
            .image {
                position: relative;
                // right: 0px;
                width: 100%;
                margin-left: 0%;
                img {
                    width: 100%;
                }
            }
            >div {
                flex: 1;
            }
        }
    }
}