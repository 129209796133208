 //-----------NFTbucks  css ---------------//
 .Essentials-banner {
     // background: linear-gradient(106.96deg, #6EF5C4 -27.85%, #0A12CA 110.71%);
     // border-radius: 40px;
     height: 100vh;
     overflow: hidden;
     box-sizing: border-box;
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     align-content: center;
     align-items: center;
     &.banner-image {
         background-image: url('../img/getbucks/get-bucks-bg.png');
         background-size: cover;
         height: 735px;
     }
     .banner-container {
         display: flex;
         flex-direction: row;
         flex-wrap: nowrap;
         align-content: center;
         align-items: flex-start;
     }
     position: relative;
     .banner-text {
         // position: absolute;
         // width: 100vw;
         // top: 0;
         // left: 0;
         // bottom: 0;
         // right: 0;
         z-index: 11;
         padding-left: 5rem;
         padding-right: 10rem;
         padding-top: 14rem !important;
         p {
             font-weight: normal;
             font-size: 20px;
             color: #FFFFFF;
         }
         h1 {
             font-weight: bold;
             font-size: 65px;
             letter-spacing: -1.5px;
             color: #FFFFFF;
             text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.45);
         }
     }
     .bg {
         // height: 100%;
         top: 0px;
         right: 0px;
         // filter: opacity(0.5);
     }
     h3 {
         font-weight: 600 !important;
         font-size: 54px!important;
         color: #FFFFFF;
     }
     p.text {
         font-weight: normal;
         font-size: 16px;
         line-height: 28px;
     }
     .btn {
         color: $blue5-light-color;
         font-weight: normal;
         font-size: 16px;
     }
     .text-content {
         width: 60% !important;
     }
     @media screen and (max-width: 561px) {
         max-height: 100%;
         .banner-container {}
         .banner-text {
             padding-left: 1rem;
             padding-right: 1rem;
             h1 {
                 font-size: 35px;
                 margin: 2rem 0;
             }
         }
         .text-content {
             width: 100% !important;
         }
     }
 }
 
 .get-bucks {
     .BrowseNfts {
         background: #04050a;
         padding-bottom: 0px !important;
         .heading {
             color: #FFFFFF;
             margin-bottom: 1rem;
         }
         .search-result {
             font-style: normal;
             font-weight: 500;
             font-size: 21px;
             color: #FFFFFF;
             margin-bottom: 1rem;
         }
         form {
             margin-bottom: 1rem;
             .input-group,
             .dropdown-toggle {
                 background-color: rgba(196, 196, 196, 0.37);
                 background-color: rgba(196, 196, 196, 0.1);
                 border-radius: 26px;
                 border: solid 1px transparent;
                 height: 52px;
             }
             .react-select-container .react-select__control {
                 background: rgba(196, 196, 196, 0.1);
                 border-radius: 26px;
                 border: none;
                 padding: 8px 5px 8px 0px;
             }
         }
     }
     .brows-nft-sec {
         .Essentials-list {
             h3 {
                 font-weight: 500;
                 font-size: 21px !important;
                 color: #FFFFFF;
             }
             .collection-list-box {
                 .img-collection {
                     border-radius: 0px;
                     .img-hover-click {
                         border-radius: 0px;
                     }
                 }
                 .card-name {
                     font-style: normal;
                     font-weight: 500;
                     font-size: 16px;
                     line-height: 25px;
                     color: #FFFFFF;
                     border-bottom: 1px solid rgba(76, 74, 106, 0.58);
                     margin-bottom: 1rem;
                     padding-bottom: 1rem;
                 }
                 .add-cart-btn {
                     background-color: #564ECE !important;
                     color: #FFFFFF !important;
                 }
             }
         }
         .view-Collection-btn {
             background-color: #564ECE !important;
             color: #FFFFFF !important;
         }
     }
 }